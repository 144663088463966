import React, { Component } from "react";

class StickyPortlet extends Component {
  render() {
    const { content, actions, title, subtitle } = this.props;

    return (
      <div
        className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile"
        id="kt_page_portlet"
      >
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              {title} <small>{subtitle}</small>
            </h3>
          </div>
          {actions && <div className="kt-portlet__head-toolbar">{actions}</div>}
        </div>
        <div className="kt-portlet__body pl-0 pr-0">{content}</div>
      </div>
    );
  }
}

export default StickyPortlet;
