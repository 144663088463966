//@flow
const hoursBefore = (h: number): Date => {
  const t = new Date();
  t.setHours(t.getHours() - h);
  return t;
};

export type Range = {
  (): Date,
  key: string
};

export const oneMonth = (): Date => hoursBefore(24 * 30);
oneMonth.key = "monthly";

export const oneWeek = (): Date => hoursBefore(24 * 7);
oneWeek.key = "weekly";

export const oneDay = (): Date => hoursBefore(24);
oneDay.key = "daily";

export const fiveMin = (): Date => {
  const start = new Date();
  start.setMinutes(start.getMinutes() - 5);
  return start;
};
fiveMin.key = "5min";
