import React from "react";
import { NavLink } from "react-router-dom";
import { useState } from 'react';
import { toAbsoluteUrl } from "../utils/utils";
import Logo from "../../app/assets/storeWiseTextWhite.svg";
import Dashboard from "../../app/assets/aside/dashboard.svg";
import Bucket from "../../app/assets/aside/bucket.svg";
import Keys from "../../app/assets/aside/keys.svg";
import Settings from "../../app/assets/aside/settings.svg";
// import Billing from "../../app/assets/aside/billing.svg";
// import Credits from "../../app/assets/aside/credits.svg";
// import Referrals from "../../app/assets/aside/referrals.svg";
import Documentation from "../../app/assets/aside/documentation.svg";


function Aside({ onClick }) {
  // const [zoneState, setZoneState] = useState(true);
  const stateKey = "toggleSidebar-state"
  const [zoneState, setZoneState] = useState(JSON.parse(localStorage.getItem(stateKey)));
  const height = "25px";

  // useEffect(() => {
  //   localStorage.getItem('toggleSidebar-state', zoneState);
  // }, [zoneState]);

  return (
    <div
      className="kt-aside  kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop"
      id="kt_aside"
    >
      {/* begin:: Aside */}
      <button onClick={() => {
        onClick();
        setZoneState(!zoneState);
      }} className="kt-aside__brand-aside-toggler" id="kt_aside_toggler">
        <div className="move">
          <div id="cta">
            <span className="arrow primera next "></span>
            <span className="arrow segunda next "></span>
          </div>
        </div>
      </button>
      {/* end:: Aside */} {/* begin:: Aside Menu */}
      <div
        className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
        id="kt_aside_menu_wrapper"
      >
        <div
          id="kt_aside_menu"
          className="kt-aside-menu kt-scroll ps ps--active-y"
          data-ktmenu-vertical={1}
          data-ktmenu-scroll={1}
          data-ktmenu-dropdown-timeout={500}
        // style={{ height: 828, overflow: "hidden" }}
        >
          {/* <Menu /> */}
          <div className="container-logo-sidebar">
            <NavLink to="/">
              <img className="expanded" src={Logo} alt="StoreWise Logo" />
            </NavLink>
            <NavLink to="/">
              <img className="short" alt="logo" src={toAbsoluteUrl("/media/logos/sw.png")} />
            </NavLink>
          </div>
          <div className="zone-container d-flex justify-content-center">
            {zoneState ? <span className="zone-sidebar">europe-west-1</span> : <span className="zone-sidebar">eu-w-1</span>}
          </div>
          <div className="mb-4">
            <span className="title-item-sidebar">Visualization</span>
            <ul className="pl-0 mt-3">
              <NavLink className="a-item-sidebar" to="/dashboard" activeClassName="active">
                <li className="mb-2 sidebar-item-menu">
                  <img className="mr-4" src={Dashboard} alt="Dashboard" height={height} />
                  Dashboard
              </li>
              </NavLink>
            </ul>
          </div>
          <div className="mb-4">
            <span className="title-item-sidebar">Data Access</span>
            <ul className="pl-0 mt-3">
              <NavLink className="a-item-sidebar" to="/buckets" activeClassName="active">
                <li className="mb-2 sidebar-item-menu">
                  <img className="mr-4" src={Bucket} alt="Bucket" height={height} />
                  Buckets
              </li>
              </NavLink>
              <NavLink className="a-item-sidebar" to="/access-keys" activeClassName="active">
                <li className="sidebar-item-menu">
                  <img className="mr-4" src={Keys} alt="Access Keys" height={height} />
                  Access Keys
              </li>
              </NavLink>
            </ul>
          </div>
          {/* <div>
            <span>Users &amp; Groups</span>
            <ul>
                <li>
              <NavLink to="/buckets">
                  Groups
              </NavLink>
              </li>
              <li>
              <NavLink to="/credentials">
                  Roles
              </NavLink>
              <NavLink to="/credentials">
                  Users
              </NavLink>
              </li>

            </ul>
          </div> */}
          <div className="mb-4">
            <span className="title-item-sidebar">Your account</span>
            <ul className="pl-0 mt-3">
              <NavLink className="a-item-sidebar" to="/settings" activeClassName="active">
                <li className="mb-2 sidebar-item-menu">
                  <img className="mr-4" src={Settings} alt="Settings" height={height} />
                  Settings
              </li>
              </NavLink>
              {/* <NavLink className="a-item-sidebar" to="/billing" activeClassName="active">
                <li className="mb-2 sidebar-item-menu">
                  <img className="mr-4" src={Billing} alt="Billing" height={height} />
                  Billing
              </li>
              </NavLink> */}
              {/* <NavLink className="a-item-sidebar" to="/credits" activeClassName="active">
                <li className="mb-2 sidebar-item-menu">
                  <img className="mr-5" src={Credits} alt="Credits" height={height} />
                  Credits
              </li>
              </NavLink>
              <NavLink className="a-item-sidebar" to="/referrals" activeClassName="active">
                <li className="mb-2 sidebar-item-menu">
                  <img className="mr-5" src={Referrals} alt="Referrals" height={height} />
                  Referrals
              </li>
              </NavLink> */}
            </ul>
          </div>
          <div className="mb-4">
            <span className="title-item-sidebar">Resources</span>
            <ul className="pl-0 mt-3">
              <a className="a-item-sidebar" href="https://docs.storewise.tech" target="_blank" rel="noopener noreferrer">
                <li className="sidebar-item-menu">
                  <img className="mr-4" src={Documentation} alt="Documentation" height={height} />
                  Documentation
                  </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
      {/* end:: Aside Menu */}
    </div>

  )
}

export default Aside