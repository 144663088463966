import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

export const StickyPortletBuckets = ({
  content,
  actions,
  title,
  subtitle,
  onReload
}) => (
  <div
    className="kt-portlet kt-portlet--last kt-portlet--head-lg kt-portlet--responsive-mobile"
    id="kt_page_portlet"
  >
    <div className="kt-portlet__head kt-portlet__head--lg">
      <div className="kt-portlet__head-label">
        <h3 className="kt-portlet__head-title position-relative">
          {title} <small>{subtitle}</small>
          <Button
            className="btn btn-sw-simple btn-refresh"
            variant="contained"
            onClick={onReload}
          >
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </h3>
      </div>
      {actions && <div className="kt-portlet__head-toolbar">{actions}</div>}
    </div>
    <div className="kt-portlet__body pl-0 pr-0">{content}</div>
  </div>
);

export default StickyPortletBuckets;
