// @flow

import React, { type Node } from "react";
import { ResponsivePie } from "@nivo/pie";

// const data = () => [
//     {
//         "id": "general",
//         "label": "general",
//         "value": {bucketsGeneralTier},
//         "color": "#384d5a"
//       }
//     //   {
//     //     "id": "cold",
//     //     "label": "cold",
//     //     "value": 438,
//     //     "color": "hsl(129, 70%, 50%)"
//     //   },
// ]

type Props = {
  number: number
};

const TierRankingPie = ({ number }: Props): Node => (
  <ResponsivePie
    className="chart-bg"
    data={[
      {
        id: "general",
        label: "general",
        value: number,
        color: "#384d5a"
      }
    ]}
    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={4}
    colors={["#26aae1"]}
    borderWidth={1}
    borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
    radialLabelsSkipAngle={10}
    radialLabelsTextXOffset={6}
    radialLabelsTextColor="#333333"
    radialLabelsLinkOffset={0}
    radialLabelsLinkDiagonalLength={16}
    radialLabelsLinkHorizontalLength={24}
    radialLabelsLinkStrokeWidth={1}
    radialLabelsLinkColor={{ from: "color" }}
    slicesLabelsSkipAngle={10}
    slicesLabelsTextColor="#333333"
    animate={true}
    motionStiffness={90}
    motionDamping={15}
    theme={{
      tooltip: {
        container: {
          background: "#232323",
          color: "#fff"
        }
      }
    }}
    legends={[
      {
        anchor: "bottom",
        direction: "row",
        translateY: 56,
        itemWidth: 100,
        itemHeight: 18,
        itemTextColor: "#999",
        symbolSize: 18,
        symbolShape: "circle",
        effects: [
          {
            on: "hover",
            style: {
              itemTextColor: "#000"
            }
          }
        ]
      }
    ]}
  />
);

export default TierRankingPie;
