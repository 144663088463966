//@flow
import { useEffect, useState } from "react";
import { useInterval } from "react-use";
import { S3MetaDBClient, useS3MetaDB } from "../../contexts";
import useBuckets from "../buckets";

import type { Range } from "../range";

export type BucketSize = {
  numbers: number,
  size: number,
  time: string
};

// key: bucket name, value: a list of BucketSize.
export type BucketSizeMap = {
  [string]: Array<BucketSize>
};

const getBucketSize = async (
  cli: S3MetaDBClient,
  name: string,
  start: Date
): Promise<Array<BucketSize>> => {
  let token = "";
  const sizes: Array<BucketSize> = [];
  for (;;) {
    const res = await cli.getBucketSize(name, start, token);
    if (res.sizes) {
      sizes.push(
        ...res.sizes.map(item => ({
          numbers: item.numbers,
          size: item.size,
          time: item.time
        }))
      );
    }
    token = res.nextToken;
    if (!token) break;
  }
  return sizes;
};

// useBucketSize fetches and returns the bucket-size data in the given time range.
// While fetching the data, it returns null.
const useBucketSize = (
  interval: number | null,
  range: Range,
  errHandler: Error => void
): ?BucketSizeMap => {
  const [sizes, setSizes] = useState<?BucketSizeMap>(null);
  const { items: buckets } = useBuckets(errHandler);
  const cli = useS3MetaDB();

  const update = () => {
    if (!cli) return;

    Promise.all(
      buckets.map(async b => ({
        [b.name]: await getBucketSize(cli, b.name, range())
      }))
    )
      .then(res =>
        setSizes(res.reduce((prev, cur) => ({ ...prev, ...cur }), {}))
      )
      .catch(errHandler);
  };
  useEffect(update, [cli, range, buckets, setSizes, errHandler]);
  useInterval(update, interval);
  return sizes;
};

export default useBucketSize;
