//@flow
import { useCallback } from "react";
import { useLocalStorage } from "react-use";

type Container<T> = {
  // value is the value stored in the local storage.
  value: T,
  // expiry is the date-time in milliseconds when the value is expired.
  expiry: number
};

const useCache = <T>(
  key: string,
  initValue: T,
  ttl: number // in milliseconds
): [T, (T) => void] => {
  const init: Container<T> = {
    value: initValue,
    expiry: Date.now() + ttl
  };
  let [c, setter, remover] = useLocalStorage(key, init);
  // Note: useLocalStorage may return null. If so, use the init value.
  if (!c) c = init;

  if (c.expiry < Date.now()) {
    remover();
    c.value = initValue;
  }
  return [
    c.value,
    useCallback((v: T) => setter({ value: v, expiry: Date.now() + ttl }), [
      setter,
      ttl
    ])
  ];
};

export default useCache;
