import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import AccessKeys from "./AccessKeys";
import Buckets from "./Buckets";
import { LayoutSplashScreen } from "../../../_metronic";
import { Error404 } from "../errors/Error404";

const Bucket = lazy(() => import("./Buckets/Bucket"));

const Settings = lazy(() => import("./settings/Full"));

// const Billing = lazy(() => import("../billing/Billing"));

// const Error = lazy(() =>
//   import("../errors/ErrorPage1")
// );

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/access-keys" component={AccessKeys} />
        <Route exact path="/buckets" component={Buckets} />
        <Route
          path="/buckets/:bucketName"
          render={props => <Bucket {...props.match.params} />}
        />
        <Route path="/settings" component={Settings} />
        {/* <Route path="/billing" component={Billing} /> */}
        <Redirect to="/error-404" component={Error404} />
      </Switch>
    </Suspense>
  );
}
