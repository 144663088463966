// @flow
import { useLocalStorage } from "react-use";
import { useIdentity } from "../../contexts";
import { credentialsStorageKey } from "./constants";
import { useAddCredential, useRemoveCredential } from "./internal";
import type { CredentialList, Credential } from "./types";

export const useCredentialList = (): CredentialList => {
  let [credentials, setCredentials] = useLocalStorage<Array<Credential>>(
    credentialsStorageKey,
    []
  );
  if (!credentials) {
    credentials = [];
  }
  const identity = useIdentity();

  const add = useAddCredential(identity.id(), credentials, setCredentials);
  const remove = useRemoveCredential(credentials, setCredentials);

  return { items: credentials, add, remove };
};

export default useCredentialList;
