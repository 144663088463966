import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";

function FormDialog({ handleClose, open, title, question, details }) {
  function close(isConfirmed) {
    handleClose && handleClose(isConfirmed);
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        close(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent className="mt-2">
        <h6 className="text-gray mb-3">{question}</h6>
        <h6 className="text-white">{details}</h6>
      </DialogContent>
      <DialogActions className="mb-3 mr-3">
        <Button
          className="cancel-btn-dialog"
          onClick={() => {
            close(false);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          className="cta-btn-dialog"
          onClick={() => {
            close(true);
          }}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FormDialog;
