export default {
  header: {
    self: {},
    items: [
      {
        title: "Buckets",
        root: true,
        alignment: "left",
        page: "buckets",
        translate: "MENU.Buckets"
      },
      {
        title: "Credentials",
        root: true,
        alignment: "left",
        page: "credentials",
        translate: "MENU.Credentials"
      },
      {
        title: "Profile",
        root: true,
        alignment: "left",
        page: "profile",
      }
    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon-diagram",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
      },
      {
        title: "Profile",
        root: true,
        icon: "flaticon-user",
        page: "react-bootstrap",
        submenu: [
          {
            title: "Profile",
            page: "profile"
          },
        ]
      },
      {
        title: "Object Store",
        root: true,
        // bullet: "line",
        // icon: "flaticon-tool",
        // page: "google-material",
        submenu: [
          {
            title: "Credentials", 
            page: "credentials",
            icon: "la-key",
            // submenu: [
            //   {
            //     title: "Container",
            //     page: "google-material/layout/container"
            //   },
            //   {
            //     title: "Grid",
            //     page: "google-material/layout/grid"
            //   },
            //   {
            //     title: "Grid list",
            //     page: "google-material/layout/grid-list"
            //   },
            //   {
            //     title: "Hidden",
            //     page: "google-material/layout/hidden"
            //   }
            // ]
          },
          {
            title: "Buckets",
            
            page: "buckets",
          },
          {
            title: "Global",
            page: "google-material/navigation/bottom-navigation",
          },
          {
            title: "Other",
            page: "google-material/surfaces/app-bar",
          },
        ]
      },
      {
        title: "Billing",
        root: true,
        icon: "flaticon-interface-10",
        page: "react-bootstrap",
        submenu: [
          {
            title: "Billing1",
            page: "react-bootstrap/blago"
          },
          {
            title: "Billing2",
            page: "react-bootstrap/blago2"
          },
          {
            title: "Billing3",
            page: "react-bootstrap/blago3"
          },
        ]
      },
      {
        title: "Settings",
        root: true,
        icon: "flaticon-settings",
        page: "react-bootstrap",
        submenu: [
          {
            title: "Settings1",
            page: "react-bootstrap/testing"
          },
          {
            title: "Settings2",
            page: "react-bootstrap/testing1"
          },
          {
            title: "Settings3",
            page: "react-bootstrap/testing2"
          },
        ]
      },
      {
        title: "Help",
        root: true,
        icon: "flaticon-questions-circular-button",
        page: "react-bootstrap",
        submenu: [
          {
            title: "Help1",
            page: "react-bootstrap/testing6"
          },
          {
            title: "Help2",
            page: "react-bootstrap/test9"
          },
          {
            title: "Help3",
            page: "react-bootstrap/test10"
          },
        ]
      },
    ]
  }
};
