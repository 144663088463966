// @flow
import React, { type Node, type StatelessFunctionalComponent } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import Login from "../pages/auth/Login";
import Logout from "../pages/auth/Logout";
import Layout from "../../_metronic/layout/Layout";
import { LayoutContextProvider } from "../../_metronic";
import { useIdentity } from "../contexts";
import * as routerHelpers from "../router/RouterHelpers";
import { Error404 } from "../pages/errors/Error404";

type Props = {
  history: any
};

export const Routes: StatelessFunctionalComponent<Props> = withRouter<Props>(
  ({ history }: Props): Node => {
    const lastLocation = useLastLocation();
    routerHelpers.saveLastLocation(lastLocation);
    const { menuConfig, userLastLocation } = useSelector(
      ({ builder: { menuConfig } }) => ({
        menuConfig,
        userLastLocation: routerHelpers.getLastLocation()
      }),
      shallowEqual
    );

    const identity = useIdentity();
    const userName = identity.username();

    return (
      <LayoutContextProvider history={history} menuConfig={menuConfig}>
        <Switch>
          {!userName ? (
            <Login />
          ) : (
            <Redirect from="/login" to={userLastLocation} />
          )}

          <Route path="/error-404" component={Error404} />
          <Route path="/logout" component={Logout} />

          {!userName ? (
            <Redirect to="/login" />
          ) : (
            <Layout>
              <HomePage userLastLocation={userLastLocation} />
            </Layout>
          )}
        </Switch>
      </LayoutContextProvider>
    );
  }
);

export default Routes;
