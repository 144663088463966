//@flow
import { S3MetaDBClient } from "../../contexts/s3metadb";
import type { S3Transaction } from "../../contexts/s3metadb";

export const getTransactions = async (
  cli: S3MetaDBClient,
  start: Date,
  filter?: S3Transaction => ?S3Transaction
): Promise<Array<S3Transaction>> => {
  let key = "";
  const newTransactions: Array<S3Transaction> = [];
  for (;;) {
    const res = await cli.listTransactions(start, key);
    if (res.transactions) {
      if (filter) {
        res.transactions.forEach(t => {
          const nt = filter(t);
          if (nt) newTransactions.push(nt);
        });
      } else {
        newTransactions.push(...res.transactions);
      }
    }
    key = res.nextKey;
    if (!key) {
      break;
    }
  }
  return newTransactions;
};
