// @flow

import React, { useState, type Node } from "react";
import { useIdentityManager } from "../../contexts";
import "../../../_metronic/_assets/sass/pages/login/login-4.scss";
import { Button, Alert } from "react-bootstrap";
import Logo from "../../assets/storeWiseText.svg";

type Props = {
  setNewUser: boolean => void
};

const Register = ({ setNewUser }: Props): Node => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [validationMessage, setPassValidationMessage] = useState(""); // password validation error message
  const [loading, setLoading] = useState(false); // general flag for loading state
  const [success, setSuccess] = useState(false); // general flag for successful registration => check email

  const { signup } = useIdentityManager();

  const validateUsername = e => {
    e.preventDefault();
    setLoading(true);
    if (username.length === 0) {
      setLoading(false);
      setPassValidationMessage("Please enter a username");
    } else validateEmail();
  };
  const validateEmail = () => {
    setLoading(true);
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      validatePassword();
    } else {
      setLoading(false);
      setPassValidationMessage("Email is not valid");
    }
  };

  const validatePassword = () => {
    if (password.length < 8) {
      setLoading(false);
      setPassValidationMessage("Password must be at least 8 letters long");
      return false;
    } else if (password !== confirmPassword) {
      setLoading(false);
      setPassValidationMessage(
        "Your confirmation password needs to be the same as your password"
      );
      return false;
    } else if (password === confirmPassword) {
      const capsCount = (password.match(/[A-Z]/g) || []).length;
      const smallCount = (password.match(/[a-z]/g) || []).length;
      const numberCount = (password.match(/[0-9]/g) || []).length;
      const symbolCount = (password.match(/\W/g) || []).length;
      if (capsCount < 1) {
        setLoading(false);
        setPassValidationMessage(
          "Password must contain at least one uppercase letter"
        );
        return false;
      } else if (smallCount < 1) {
        setLoading(false);
        setPassValidationMessage(
          "Password must contain at least one lowercase letter"
        );
        return false;
      } else if (numberCount < 1) {
        setLoading(false);
        setPassValidationMessage("Password must contain at least one number");
        return false;
      } else if (symbolCount < 1) {
        setLoading(false);
        setPassValidationMessage(
          "Password must contain at least one special character."
        );
        return false;
      }

      setPassValidationMessage("");
      return SignupAttempt();
    }
  };

  const SignupAttempt = async () => {
    try {
      await signup(username, password, email);
      setPassValidationMessage("");
      setLoading(false);
      setSuccess(true);
    } catch (err) {
      setPassValidationMessage(err);
      setLoading(false);
      setSuccess(false);
    }
  };

  return (
    <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
      <div
        className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v4 kt-login--signin"
        id="kt_login"
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
            <div className="kt-login__container">
              <div className="kt-login__logo">
                <a href="/">
                  <img src={Logo} height="60" alt="StoreWise Logo" />
                </a>
              </div>
              <div className="kt-login__signin">
                <form className="kt-form" autoComplete={"off"}>
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Username"
                      name="newUser"
                      onChange={e => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="input-group">
                    <input
                      className="form-control password-type"
                      type="password"
                      placeholder="Password"
                      autoComplete={"off"}
                      name="newUserPassword"
                      onChange={e => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="input-group">
                    <input
                      className="form-control password-type"
                      type="password"
                      placeholder="Confirm Password"
                      autoComplete={"off"}
                      name="confirmPassword"
                      onChange={e => setConfirmPassword(e.target.value)}
                    />
                  </div>

                  <div className="kt-login__actions">
                    {validationMessage.length > 0 ? (
                      <Alert variant="warning">{validationMessage}</Alert>
                    ) : (
                      ""
                    )}
                    {success ? (
                      <>
                        <Alert variant="success">
                          Your account was successfully created. Please check
                          your email to verify it!
                        </Alert>
                      </>
                    ) : (
                      ""
                    )}
                    <Button
                      type="submit"
                      className="btn btn-sw-cta-sign-in"
                      ariant="contained"
                      onClick={validateUsername}
                    >
                      {loading ? "Signing you up..." : "Sign Up"}
                    </Button>
                  </div>
                  <div className="row p-0 mr-0 ml-0 kt-login__extra">
                    <div className="col p-0 kt-align-left">
                      <button
                        onClick={() => setNewUser(false)}
                        id="kt_login_forgot"
                        className="kt-login__link"
                      >
                        Already have an account?
                      </button>
                    </div>
                  </div>
                  <input type="hidden" value="prayer" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
