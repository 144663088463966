// @flow
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback
} from "react";
import type { Node } from "react";
import AmplifyIdentity from "./amplify";
import type { Identity, IdentityManager } from "./types";

export type { Identity, IdentityManager };

const IdentityContext = createContext<Identity>(new AmplifyIdentity());

const IdentityDispatchContext = createContext<(Identity) => void>(() => {});

export const useIdentity = (): Identity => {
  return useContext(IdentityContext);
};

export const useIdentityManager = (): IdentityManager => {
  const setIdentity = useContext(IdentityDispatchContext);
  const login = useCallback(
    async (username: string, password: string): Promise<void> => {
      setIdentity(await AmplifyIdentity.login(username, password));
    },
    [setIdentity]
  );
  return {
    login,
    signup: AmplifyIdentity.signup,
    resendVerificationCode: AmplifyIdentity.resendVerificationCode
  };
};

type Props = {
  children?: Node
};

export const IdentityProvider = ({ children }: Props): Node => {
  const [identity, setIdentity] = useState(new AmplifyIdentity());
  useEffect(() => {
    AmplifyIdentity.init()
      .then(setIdentity)
      // TODO: error handling.
      .catch(console.error);
  }, [setIdentity]);
  return (
    <IdentityContext.Provider value={identity}>
      <IdentityDispatchContext.Provider value={setIdentity}>
        {children}
      </IdentityDispatchContext.Provider>
    </IdentityContext.Provider>
  );
};
