import React, { useState, useEffect } from "react";
import CheckBox from "./CheckBox";
import moment from "moment";
import CopyableField from "../components/CopyableFieldAccessKey";

function ListTable({
  className,
  enableSelection,
  renderActions,
  titles,
  items,
  onChange
}) {
  const [selections, setSelections] = useState([]);

  useEffect(() => {
    onChange && onChange(selections);
  }, [selections, onChange]);

  function handleBulkSelection(isSelected) {
    const ss = [];
    if (isSelected) {
      items.map(item => {
        return ss.push(item.id);
      });
    }
    setSelections(ss);
  }

  function updateItemSelection(id, isSelected) {
    const ss = [...selections];
    const index = ss.indexOf(id);
    if (index !== -1) {
      delete ss.splice(index, 1);
    }
    if (isSelected) {
      ss.push(id);
    }
    setSelections(ss);
  }

  function renderCols() {
    return (
      <tr>
        {enableSelection && (
          <td>
            <CheckBox onChange={handleBulkSelection} />
          </td>
        )}
        {// TODO: calc width automatically by col count and
        // small|normal|big preferences.
        titles.map(({ name, width: _width }, index) => {
          return <td key={index}>{name}</td>;
        })}
        {renderActions && <td></td>}
      </tr>
    );
  }

  function renderItems() {
    return items.map((item, index) => {
      const { id, values } = item;
      const isSelected = selections.indexOf(id) !== -1;

      return (
        <tr key={index}>
          {enableSelection && (
            <td>
              <CheckBox
                checked={isSelected}
                onChange={isSelected => {
                  updateItemSelection(id, isSelected);
                }}
              />
            </td>
          )}
          {values.map(({ name, description }, index) => {
            if (name instanceof Date) {
              name = moment(name).format("LLL");
            }
            return (
              <td key={index}>
                {index === 0 ? (
                  <div>
                    <span className="kt-widget11__title">
                      {/* { name }  */}
                      <CopyableField value={name} />
                    </span>

                    {description && (
                      <span className="kt-widget11__sub">{description}</span>
                    )}
                  </div>
                ) : (
                  name
                )}
              </td>
            );
          })}
          {renderActions && <td>{renderActions(item)}</td>}
        </tr>
      );
    });
  }

  return (
    <div className="kt-widget11">
      <div className="table-responsive">
        <table className={`table ${className}`}>
          <thead>{renderCols()}</thead>
          <tbody className="striped credentials-tbody">{renderItems()}</tbody>
        </table>
      </div>
    </div>
  );
}

export default ListTable;
