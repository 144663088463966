//@flow
import React, { type Node, type ComponentType } from "react";
import { ResponsiveBar } from "@nivo/bar";
import type { BucketSize, BucketSizeMap } from "../../../hooks/metrics";
import type { TimeFrame } from "../types";
import Axis from "./Axis";

type Data = {
  date: string,
  total: number,
  tooltipDate: string
};

const theme = {
  tooltip: {
    container: {
      background: "#232323",
      color: "#fff"
    }
  },
  axis: {
    domain: {
      line: {
        stroke: "rgba(255,255,255,0.2)",
        strokeWidth: 1
      }
    }
  }
};

// formatDate extracts YYYY--MM--DD
const formatDate = (ts: Date): string => ts.toISOString().split("T")[0];

const histogram = (
  objectsPerTime: BucketSizeMap,
  key: BucketSize => string
): { [string]: number } => {
  const bins = {};
  Object.keys(objectsPerTime).forEach(name => {
    const localBins = {};
    objectsPerTime[name].forEach(t => {
      localBins[key(t)] = t.numbers || 0;
    });
    Object.keys(localBins).forEach(key => {
      if (!bins[key]) {
        bins[key] = 0;
      }
      bins[key] += localBins[key];
    });
  });
  return bins;
};

const parseObjectsPerTime = (
  objectsPerTime: BucketSizeMap,
  timeFrame: TimeFrame
): Array<Data> => {
  switch (timeFrame) {
    case "daily": {
      const bins = histogram(
        objectsPerTime,
        bs => `${new Date(bs.time).getHours()}:00`
      );
      return [...Array(24)].map((_, i) => {
        const ts = new Date();
        ts.setHours(ts.getHours() - 23 + i);
        const key = `${ts.getHours()}:00`;
        return {
          date: key,
          total: bins[key] || 0,
          tooltipDate: key
        };
      });
    }
    case "weekly": {
      const bins = histogram(objectsPerTime, bs =>
        new Date(bs.time).getDate().toString()
      );
      return [...Array(7)].map((_, i) => {
        const ts = new Date();
        ts.setDate(ts.getDate() - 7 + 1 + i);
        const key = ts.getDate().toString();
        return {
          date: key,
          total: bins[key] || 0,
          tooltipDate: formatDate(ts)
        };
      });
    }
    case "monthly": {
      const bins = histogram(objectsPerTime, bs =>
        new Date(bs.time).getDate().toString()
      );
      return [...Array(30)].map((_, i) => {
        const ts = new Date();
        ts.setDate(ts.getDate() - 30 + 1 + i);
        const key = ts.getDate().toString();
        return {
          date: key,
          total: bins[key] || 0,
          tooltipDate: formatDate(ts)
        };
      });
    }
    default: {
      // shouldn't reach here.
      return [];
    }
  }
};

type Props = {
  timeFrame: TimeFrame,
  objectsPerTime: BucketSizeMap
};

const ObjectChart = ({ timeFrame, objectsPerTime }: Props): Node => {
  const data = parseObjectsPerTime(objectsPerTime, timeFrame);

  if (!Object.keys(objectsPerTime).length) {
    return (
      <>
        <ResponsiveBar className="chart-bg" data={[]} height={400} />
        <h5 className="graph-warning">No number of objects available</h5>
      </>
    );
  }

  return (
    <>
      <Axis timeFrame={timeFrame} />
      <ResponsiveBar
        className="chart-bg"
        data={data}
        height={200}
        keys={["total"]}
        indexBy="date"
        enableLabel={false}
        margin={{ top: 50, right: 130, bottom: 50, left: 70 }}
        padding={0.1}
        colors={["#1e5f7e"]}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        borderRadius={2}
        tooltip={({ id, value, data }) => (
          <div className="d-flex flex-column">
            <strong>
              <p className="p-1 m-0">{data.tooltipDate}</p>
            </strong>
            <p className="p-1 m-0">
              {id}: <strong>{value}</strong>
            </p>
          </div>
        )}
        theme={theme}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 12,
          tickRotation: 0,
          format: value => `${Number(value)}`,
          legendPosition: "middle",
          legendOffset: -100,
          tickValues: 3
        }}
        enableGridY={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        legends={[]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </>
  );
};

export default (React.memo(ObjectChart): ComponentType<Props>);
