//@flow
import { useS3MetaDB } from "../../contexts";
import { useCache } from "../index";
import { DefaultTTL, objectStorageKeyPrefix } from "./constants";
import { useFetchObjects } from "./internal";
import type { Objects, Container } from "./types";

const useObjects = (bucket: string): Objects => {
  const [objects, setObjects] = useCache<Container>(
    objectStorageKeyPrefix + bucket,
    { items: [], token: "" },
    DefaultTTL
  );

  const cli = useS3MetaDB();
  const fetch = useFetchObjects(cli, bucket, objects, setObjects);
  return {
    bucket,
    items: objects.items,
    fetch,
    fetched: !!objects.items.length && !objects.token
  };
};

export default useObjects;
