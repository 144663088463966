// @flow
import { useIdentity } from "../../contexts";
import { Redirect } from "react-router-dom";
import React, { type Node } from "react";

const Logout = (): Node => {
  const identity = useIdentity();

  // Cleanup local storage.
  const storage = global.localStorage;
  [...Array(storage.length).keys()]
    .map(i => storage.key(i))
    .filter(key => key.startsWith("storewise."))
    .forEach(key => storage.removeItem(key));

  // TODO: show this error message.
  identity.logout().catch(err => console.error(err));
  // Since logout reloads the page, this component won't render.
  return <Redirect to="/login" />;
};

export default Logout;
