// @flow
import React, { useState, useRef, useCallback, type Node } from "react";
import { Fade } from "@material-ui/core";
import ListTable from "../../../components/ListTable";
import StickyPortlet from "../../../components/StickyPortlet";
import ConfirmDialog from "../../../components/ConfirmDialog";
import type { SecretCredential } from "../../../hooks/credentials";
import orphan from "../../../styles/orphan";
import { useCredentialList } from "../../../hooks";
import CreateAccessKeyDialog from "./CreateAccessKeyDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faKey } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

const titles = [
  {
    name: "Access Key ID",
    width: 15
  },
  {
    name: "Creation Date",
    width: 15
  }
];

const initialNewKeyPairState: SecretCredential = {
  accessKeyId: "",
  secretAccessKey: ""
};

const compareList = (a, b): number => {
  if (a.values[1].name > b.values[1].name) return -1;
  if (a.values[1].name < b.values[1].name) return 1;
  return 0;
};

const Credentials = (): Node => {
  const [selectedList, setSelectedList] = useState<Array<string>>([]);
  const [
    isCreateAccessKeyDialogOpen,
    setIsCreateAccessKeyDialogOpen
  ] = useState(false);
  const [
    isDeleteConfirmationDialogOpen,
    setIsDeleteConfirmationDialogOpen
  ] = useState(false);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState<{
    question: string,
    details: string
  }>({
    question: "",
    details: ""
  });

  const [{ accessKeyId, secretAccessKey }, setNewKeyPair] = useState(
    initialNewKeyPairState
  );
  const credentials = useCredentialList();

  const processing = useRef(false);
  const handleAddCredential = useCallback(async () => {
    if (processing.current) {
      return;
    }
    processing.current = true;
    try {
      setNewKeyPair(credentials.add());
    } finally {
      processing.current = false;
    }
  }, [credentials]);

  const showDeleteConfirmationDialog = useCallback(
    ids => {
      setDeleteConfirmationText({
        question:
          "You are about to delete following access key(s). Do you confirm to proceed?",
        details: ids.join(", ")
      });
      setIsDeleteConfirmationDialogOpen(true);
    },
    [setDeleteConfirmationText, setIsDeleteConfirmationDialogOpen]
  );

  const handleDeleteConfirmationDialogClose = useCallback(
    isConfirmed => {
      if (isConfirmed) {
        credentials.remove(...selectedList);
      }
      setIsDeleteConfirmationDialogOpen(false);
    },
    [credentials, selectedList, setIsDeleteConfirmationDialogOpen]
  );

  function createCreds() {
    // TODO: show this error message.
    handleAddCredential().catch(console.error);
    setIsCreateAccessKeyDialogOpen(true);
  }

  function credentialsToTableItems(credentials) {
    const list = credentials.map(cred => {
      return {
        id: cred.accessKeyId,
        values: [
          {
            name: cred.accessKeyId
          },
          {
            name: new Date(cred.creationTime)
          }
        ]
      };
    });
    return list.sort(compareList);
  }

  function handleCredentialDelete(id) {
    setSelectedList([id]);
    showDeleteConfirmationDialog([id]);
  }

  return (
    <div>
      <StickyPortlet
        title="Access Keys"
        subtitle={credentials.items.length + " access keys found"}
        actions={
          <>
            {selectedList.length > 0 && (
              <Fade in={true}>
                <Button
                  variant="outlined"
                  size="medium"
                  style={{
                    color: "rgba(255, 255, 255, 0.5)",
                    borderColor: "rgba(255, 255, 255, 0.5)",
                    marginRight: 10
                  }}
                  onClick={() => {
                    showDeleteConfirmationDialog(selectedList);
                  }}
                >
                  Delete Selected
                </Button>
              </Fade>
            )}
            <Button
              className="btn btn-sw-primary"
              variant="contained"
              onClick={createCreds}
            >
              <FontAwesomeIcon size="lg" icon={faKey} />
              <span className="ml-2">Create Access Key</span>
            </Button>
          </>
        }
        content={
          <ListTable
            className={"table-credentials"}
            renderActions={item => {
              return (
                <FontAwesomeIcon
                  className="remove-icon"
                  size="lg"
                  icon={faTrashAlt}
                  style={{ ...orphan.cursorPointer }}
                  onClick={() => {
                    handleCredentialDelete(item.id);
                  }}
                />
              );
            }}
            enableSelection={true}
            titles={titles}
            items={credentialsToTableItems(credentials.items)}
            onChange={setSelectedList}
          />
        }
      />

      <CreateAccessKeyDialog
        open={isCreateAccessKeyDialogOpen}
        accessKeyId={accessKeyId}
        accessKeySecret={secretAccessKey}
        handleClose={() => {
          setIsCreateAccessKeyDialogOpen(false);
        }}
      />

      <ConfirmDialog
        open={isDeleteConfirmationDialogOpen}
        title="Confirm to Delete Access Keys"
        question={deleteConfirmationText.question}
        details={deleteConfirmationText.details}
        handleClose={handleDeleteConfirmationDialogClose}
      />
    </div>
  );
};

export default Credentials;
