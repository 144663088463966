// @flow
import React, { createContext, useContext } from "react";
import type { Node } from "react";
import { useIdentity } from "../identity";
import { S3MetaDBClient, useS3MetaDBClient } from "./client";

export type {
  S3Bucket,
  S3BucketInfo,
  ListObjectsResult,
  S3Object,
  S3Transaction,
  ListTransactionsResult,
  ContractStatus
} from "./types";
export type { BucketSize } from "./swagger";

export {
  AuthenticatedUsersID,
  AllUsersID,
  CanonicalUserType,
  GroupType,
  FullControl,
  Read,
  Write,
  Enabled
} from "./constants";
export { S3MetaDBClient } from "./client";

const S3MetaDBContext = createContext<?S3MetaDBClient>(null);

// useS3MetaDB asynchronously creates a new API client that uses the given token.
export const useS3MetaDB = (): ?S3MetaDBClient => useContext(S3MetaDBContext);

type Props = {
  children?: Node
};

export const S3MetaDBProvider = ({ children }: Props): Node => {
  const identity = useIdentity();
  const client = useS3MetaDBClient(identity);
  return (
    <S3MetaDBContext.Provider value={client}>
      {children}
    </S3MetaDBContext.Provider>
  );
};
