// @flow
import React, { useState, type Node } from "react";
import CheckBox from "../../../components/CheckBox";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import RadioGroup, { Radio } from "../../../components/RadioGroup";
import type { CreateBucket } from "../../../hooks/buckets";
import Error from "../Buckets/errors/Error";

type Props = {
  open: boolean,
  handleClose: () => void,
  handleOpen: () => void,
  handleSubmit: CreateBucket
};

const NewBucketDialog = ({
  open,
  handleClose,
  handleSubmit,
  handleOpen: _handleOpen
}: Props): Node => {
  const [name, setName] = useState("");
  const [acl, setACL] = useState("private");
  const [versioning, setVersioning] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorDialogOpen, setErrorDialogOpen] = useState(false);

  function submit() {
    handleSubmit(name, acl, versioning)
      .then(() => {
        setHasError(false);
        handleClose();
      })
      .catch(err => {
        removedBucketError(err.message);
      });
  }

  const removedBucketError = error => {
    setErrorMessage(error || "Bucket could not be created");
    setErrorDialogOpen(true);
  };

  const aclOptions = [
    {
      name: "Private",
      value: "private",
      description:
        "Only bucket owner gets full control. No one else has access rights."
    },
    {
      name: "Authenticated Read",
      value: "authenticated-read",
      description:
        "Bucket owner gets full control. Other StoreWise users get access to list the objects in this bucket."
    },
    {
      name: "Public Read",
      value: "public-read",
      description:
        "Bucket owner gets full control. Any other users get access to list the objects in this bucket."
    }
  ];

  return (
    <div>
      <Dialog id="bucket-creation" open={open} onClose={handleClose}>
        <DialogTitle>
          <FontAwesomeIcon size="lg" icon={faFolder} />
          <span className="ml-2">New Bucket</span>
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Bucket Name"
            fullWidth
            inputProps={{
              style: {
                color: "white"
              }
            }}
            onChange={event => {
              setName(event.target.value);
            }}
            onKeyPress={ev => {
              if (ev.key === "Enter") {
                ev.preventDefault();
                submit();
              }
            }}
          />
          {!!hasError && (
            <span className="bucket-error-message">{errorMessage}</span>
          )}
          <div className="my-4">
            A bucket name can contain lowercase letters, numbers, periods (.),
            and hyphens (-). The name also must be a valid DNS name and unique
            in the world.
          </div>

          <div>
            <h6 className="pb-3">Bucket Access Control</h6>
            <RadioGroup onChange={setACL}>
              {aclOptions.map((item, i) => (
                <Radio key={i} {...item} />
              ))}
            </RadioGroup>
          </div>
          <div className="my-4">
            <h6>Versioning</h6>
            <div className="d-flex">
              <CheckBox checked={versioning} onChange={setVersioning} />
              <span>
                Keep all versions of an object in the same bucket.
                {/* Learn More -> */}
              </span>
            </div>
          </div>
          {/* <div className="my-4">
          <h6>Server access logging</h6>
          <div className="d-flex">
            <CheckBox />
            <span>
              Log requests for access to your bucket.
            </span>
          </div>
        </div>
        <div className="my-4">
          <h6>Object-level logging</h6>
          <div className="d-flex">
            <CheckBox />
            <span>
              Record object-level API activity.
            </span>
          </div>
        </div> */}
        </DialogContent>
        <DialogActions className="mb-3 mr-3">
          <Button
            className="cancel-btn-dialog"
            onClick={() => {
              handleClose();
              setHasError(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button className="cta-btn-dialog" onClick={submit} color="primary">
            <FontAwesomeIcon size="lg" icon={faFolderPlus} />
            <span className="ml-2">Create</span>
          </Button>
        </DialogActions>
      </Dialog>
      <Error
        open={isErrorDialogOpen}
        handleClose={() => {
          setErrorDialogOpen(false);
        }}
        message={errorMessage}
      />
    </div>
  );
};

export default NewBucketDialog;
