// @flow

import React, { useState, type Node } from "react";
import { useIdentityManager } from "../../contexts";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import "../../../_metronic/_assets/sass/pages/login/login-4.scss";
import { Button, Alert } from "react-bootstrap";
import Logo from "../../assets/storeWiseText.svg";

const Login = (): Node => {
  const [newUser, setNewUser] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { login, resendVerificationCode } = useIdentityManager();

  const submit = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await login(username, password);
      if (response) {
        setLoginError("");
        setLoading(false);
      }
    } catch (err) {
      setLoginError(err.message);
      console.log(err.message);
      setLoading(false);
    }
  };

  if (newUser) {
    return <Register setNewUser={setNewUser} />;
  }
  if (forgotPassword) {
    return <ForgotPassword setForgotPassword={setForgotPassword} />;
  }

  return (
    <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
      <div
        className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v4 kt-login--signin"
        id="kt_login"
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
            <div className="kt-login__container">
              <div className="kt-login__logo">
                <a href="/">
                  <img src={Logo} height="60" alt="StoreWise Logo" />
                </a>
              </div>
              <div className="kt-login__signin">
                {/* <div className="kt-login__head">
                    <h3 className="kt-login__title">Login</h3>
                  </div> */}
                <form className="kt-form">
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Username"
                      name="email"
                      autoComplete="off"
                      onChange={e => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Password"
                      name="password"
                      onChange={e => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="kt-login__actions">
                    {loginError.length > 0 ? (
                      <Alert variant="danger">{loginError}</Alert>
                    ) : (
                      ""
                    )}
                    {loginError === "User is not confirmed." && (
                      <div className="mb-4">
                        <span>Didn&apos;t get your verification?</span>
                        <Button
                          className="btn btn-sw-outlined ml-3"
                          variant="contained"
                          onClick={() =>
                            resendVerificationCode(username).catch(err =>
                              // TODO: show this error message
                              console.error(err)
                            )
                          }
                        >
                          Resend verification
                        </Button>
                      </div>
                    )}
                    <Button
                      type="submit"
                      className="btn btn-sw-cta-sign-in"
                      variant="contained"
                      onClick={submit}
                      disabled={loading}
                    >
                      {loading ? "Logging in..." : "Login"}
                    </Button>
                  </div>
                  <div className="row p-0 mr-0 ml-0 kt-login__extra">
                    <div className="col p-0 kt-align-left">
                      <button
                        onClick={() => setNewUser(!newUser)}
                        id="kt_signup"
                        className="kt-login__link kt-fake__border"
                      >
                        Don&apos;t have an account?
                      </button>
                    </div>
                  </div>
                  <div className="row p-0 mr-0 ml-0 kt-login__extra">
                    <div className="col p-0">
                      <label className="kt-checkbox">
                        <input type="checkbox" name="remember" /> Remember me
                        <span />
                      </label>
                    </div>
                    <div className="col p-0 kt-align-right">
                      <button
                        onClick={() => setForgotPassword(!forgotPassword)}
                        id="kt_login_forgot"
                        className="kt-login__link"
                      >
                        Forget Password ?
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
