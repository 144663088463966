//@flow
import React, { type Node } from "react";
import fileSize from "filesize";

type Props = {
  size: number
};

export const FileSize = ({ size }: Props): Node => {
  const { value, symbol } = fileSize(size, {
    output: "object",
    standard: "iec"
  });
  return (
    <h3 className="dashboard-value-size-1 font-weight-600">
      {value}
      <span className="unit pl-2">{symbol}</span>
    </h3>
  );
};

export default FileSize;
