//@flow
import { useEffect } from "react";
import { useInterval } from "react-use";
import { useS3MetaDB } from "../../contexts";
import useCache from "../cache";
import type { S3Transaction } from "../../contexts/s3metadb";

import { getTransactions } from "./internals";
import type { Range } from "../range";

export const storageKeyPrefix = "storewise.s3.transactions.";
export const DefaultTTL = 60 * 60 * 1000; // 1hour.

const useTransactions = (
  range: Range,
  interval: number | null, // if interval is null, the transactions will never updated.
  errHandler: Error => void,
  filter?: S3Transaction => ?S3Transaction
): ?Array<S3Transaction> => {
  const [transactions, setTransactions] = useCache<?Array<S3Transaction>>(
    storageKeyPrefix + range.key,
    null,
    DefaultTTL
  );
  const cli = useS3MetaDB();

  const update = () => {
    if (!cli) return;
    getTransactions(cli, range(), filter)
      .then(setTransactions)
      .catch(errHandler);
  };
  useEffect(() => {
    if (transactions) return;
    update();
    // Note: we need to skip including `update` in the dependencies below.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cli, range, errHandler, filter, setTransactions, transactions]);
  useInterval(update, interval);
  return transactions;
};

export default useTransactions;
