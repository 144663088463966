/*
 * Copyright (C) 2019 Junpei Kawamoto
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import moment from "moment";
import { createLogger, format } from "winston";
import Transport from "winston-transport";
import { LogLevelStorageKey } from "../../app/constants";

const TimeStampFormat = "YYYY-MM-DD HH:mm:ss";

export const isTrue = str => str && str.toLowerCase() === "true";

class BrowserConsole extends Transport {
  constructor(opts) {
    super(opts);

    this.name = "BrowserConsole";
    this.levels = {
      error: 0,
      warn: 1,
      info: 2,
      debug: 4
    };

    this.methods = {
      error: "error",
      warn: "warn",
      info: "info",
      verbose: "info",
      debug: "log"
    };

    this.level =
      opts.level &&
      Object.prototype.hasOwnProperty.call(this.levels, opts.level)
        ? opts.level
        : "info";
  }

  log(info, callback) {
    setImmediate(() => {
      this.emit("logged", info);
    });

    const val = this.levels[info.level];
    const mappedMethod = this.methods[info.level];
    if (val <= this.levels[this.level]) {
      // eslint-disable-next-line no-console
      console[mappedMethod](
        `${moment(info.timestamp).format(TimeStampFormat)} ${info.message}`
      );
    }
    callback();
  }
}

const transports = [
  new BrowserConsole({
    level: localStorage.getItem(LogLevelStorageKey) || "info"
  })
];

const logger = createLogger({
  level: "debug",
  format: format.combine(format.timestamp()),
  transports
});

export default logger;
