//@flow
import React, { type Node } from "react";
import classNames from "classnames";
import type { TimeFrame } from "../types";

// formatDate extracts YYYY--MM--DD
const formatDate = (ts: Date): string => ts.toISOString().split("T")[0];

const axis = (timeFrame: TimeFrame): Array<string> => {
  switch (timeFrame) {
    case "daily": {
      const hoursInDay = 24;
      return [...Array(hoursInDay)]
        .map((_, i) => {
          const ts = new Date();
          ts.setHours(ts.getHours() - i);
          return `${ts.getHours()}:00`;
        })
        .reverse();
    }
    case "weekly": {
      const daysInWeek = 7;
      return [...Array(Math.ceil(daysInWeek))].map((_, i) => {
        const ts = new Date();
        ts.setDate(ts.getDate() - daysInWeek + 1 + i);
        return formatDate(ts);
      });
    }
    case "monthly": {
      const daysInMonth = 30;
      const skip = 4;
      return [...Array(Math.ceil(daysInMonth / skip))].map((_, i) => {
        const ts = new Date();
        ts.setDate(ts.getDate() - daysInMonth + 1 + i * skip);
        return formatDate(ts);
      });
    }
    default:
      // shouldn't reach here.
      return [];
  }
};

type Props = {
  timeFrame: TimeFrame
};

const Axis = ({ timeFrame }: Props): Node => (
  <div className="horizontal-date">
    <ul
      className={classNames("p-0 d-flex justify-content-around mt-3", {
        "hour-date-axis": timeFrame === "daily"
      })}
    >
      {axis(timeFrame).map(listItem => (
        <li className="date-x-axis" key={listItem}>
          {listItem}
        </li>
      ))}
    </ul>
  </div>
);

export default Axis;
