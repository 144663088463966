export const S3Endpoint = process.env.REACT_APP_S3ENDPOINT;

export const CanonicalHost = "https://s3.storewise.tech";

export const CognitoRegion = "eu-central-1";
export const IdentityPoolId =
  "eu-central-1:4b1f9baf-7fca-4974-84a9-1970a0a4a696";
export const UserPoolId = "eu-central-1_F4BSXLPBl";
export const UserPoolClientId = "1mvao66135fa3p3dl5g1c5n7do";
export const UserPoolDomain = "storewise-tech";
export const CognitoIdp = `cognito-idp.${CognitoRegion}.amazonaws.com/${UserPoolId}`;

export const TokenStorageKey = "storewise.id_token";
export const LogLevelStorageKey = "storewise.s3.log";

export const StripeApiKey = "pk_live_C2jHd8kSw09wH5wHIylZmjkh";

export const GoogleAnalyticsID = "UA-131936607-2";
