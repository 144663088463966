//@flow
import React, { type Node, type ComponentType } from "react";
import { ResponsiveBar } from "@nivo/bar";
import filesize from "filesize";
import type { S3Transaction } from "../../../contexts/s3metadb";
import type { TimeFrame } from "../types";
import Axis from "./Axis";

type FileSize = {
  value: number,
  symbol: string,
  exponent: number
};

type Data = {
  date: string,
  total: number,
  tooltipDate: string
};

const theme = {
  tooltip: {
    container: {
      background: "#232323",
      color: "#fff"
    }
  },
  axis: {
    domain: {
      line: {
        stroke: "rgba(255,255,255,0.2)",
        strokeWidth: 1
      }
    }
  }
};

// formatDate extracts YYYY--MM--DD
const formatDate = (ts: Date): string => ts.toISOString().split("T")[0];

const histogram = (
  transactions: Array<S3Transaction>,
  key: S3Transaction => string
): { bins: { [string]: number }, maxFileSize: FileSize } => {
  const bins = {};
  for (const t: S3Transaction of transactions) {
    const k = key(t);
    if (!bins[k]) {
      bins[k] = 0;
    }
    bins[k] += t.download || 0;
  }
  return {
    bins,
    maxFileSize: filesize(Math.max(...Object.keys(bins).map(k => bins[k])), {
      output: "object",
      standard: "iec"
    })
  };
};

const parseTransactions = (
  transactions: Array<S3Transaction>,
  timeFrame: TimeFrame
): { data: Array<Data>, maxFileSize: FileSize } => {
  switch (timeFrame) {
    case "daily": {
      const { bins, maxFileSize } = histogram(
        transactions,
        t => `${new Date(t.time).getHours()}:00`
      );
      return {
        maxFileSize,
        data: [...Array(24)].map((_, i) => {
          const ts = new Date();
          ts.setHours(ts.getHours() - 23 + i);
          const key = `${ts.getHours()}:00`;
          return {
            date: key,
            total: filesize(bins[key] || 0, {
              exponent: maxFileSize.exponent,
              output: "object",
              standard: "iec"
            }).value,
            tooltipDate: key
          };
        })
      };
    }
    case "weekly": {
      const { bins, maxFileSize } = histogram(transactions, t =>
        new Date(t.time).getDate().toString()
      );
      return {
        maxFileSize,
        data: [...Array(7)].map((_, i) => {
          const ts = new Date();
          ts.setDate(ts.getDate() - 7 + 1 + i);
          const key = ts.getDate().toString();
          return {
            date: key,
            total: filesize(bins[key] || 0, {
              exponent: maxFileSize.exponent,
              output: "object",
              standard: "iec"
            }).value,
            tooltipDate: formatDate(ts)
          };
        })
      };
    }
    case "monthly": {
      const { bins, maxFileSize } = histogram(transactions, t =>
        new Date(t.time).getDate().toString()
      );
      return {
        maxFileSize,
        data: [...Array(30)].map((_, i) => {
          const ts = new Date();
          ts.setDate(ts.getDate() - 30 + 1 + i);
          const key = ts.getDate().toString();
          return {
            date: key,
            total: filesize(bins[key] || 0, {
              exponent: maxFileSize.exponent,
              output: "object",
              standard: "iec"
            }).value,
            tooltipDate: formatDate(ts)
          };
        })
      };
    }
    default: {
      // shouldn't reach here.
      return {
        data: [],
        maxFileSize: filesize(0, {
          output: "object",
          standard: "iec"
        })
      };
    }
  }
};

type Props = {
  timeFrame: TimeFrame,
  transactions: Array<S3Transaction>
};

const DownloadChart = ({ timeFrame, transactions }: Props): Node => {
  const { data, maxFileSize } = parseTransactions(transactions, timeFrame);

  if (data.map(d => d.total).reduce((a, c) => a + c, 0) === 0) {
    return (
      <>
        <ResponsiveBar className="chart-bg" data={[]} height={400} />
        <h5 className="graph-warning">No downloaded data</h5>
      </>
    );
  }
  return (
    <>
      <Axis timeFrame={timeFrame} />
      <ResponsiveBar
        className="chart-bg"
        data={data}
        height={200}
        keys={["total"]}
        indexBy="date"
        enableLabel={false}
        margin={{ top: 50, right: 130, bottom: 50, left: 70 }}
        padding={0.1}
        colors={["#1e5f7e"]}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        borderRadius={2}
        tooltip={({ id, value, data }) => (
          <div className="d-flex flex-column">
            <strong>
              <p className="p-1 m-0">{data.tooltipDate}</p>
            </strong>
            <p className="p-1 m-0">
              {id}:{" "}
              <strong>
                {value} {maxFileSize.symbol}
              </strong>
            </p>
          </div>
        )}
        theme={theme}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 12,
          tickRotation: 0,
          // legend: "amount in " + maxFileSize.symbol,
          format: value => `${Number(value)} ${maxFileSize.symbol}`,
          legendPosition: "middle",
          legendOffset: -100,
          tickValues: 3
        }}
        enableGridY={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        legends={[]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </>
  );
};

export default (React.memo(DownloadChart): ComponentType<Props>);
