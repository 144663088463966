// @flow
import axios from "axios";
import React, { useState, type Node, useMemo } from "react";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import {
  useBucketSize,
  useTransactions,
  // useGeolocations,
  useContracts,
  useTraffic,
  useMetrics,
  oneDay,
  oneWeek,
  oneMonth
} from "../../hooks";
import type { BucketSizeMap } from "../../hooks/metrics";
// import type { S3Transaction } from "../../hooks";
import type { S3Transaction } from "../../contexts/s3metadb";
// import type { BucketSizeMap } from "../../hooks/metrics";
// import type { BucketSizeMap, S3Transaction } from "../../hooks";
// import { Portlet } from "../../partials/content/Portlet";
// import HostsMap from "../../components/HostsMap";
import { Row, Col } from "reactstrap";
import ObjectChart from "./dataInsight/ObjectChart";
import FileSize from "./FileSize";
// import FirstByteLatency from "./latency/FirstByteLatency";
import GetLatency from "./latency/GetLatency";
import PutLatency from "./latency/PutLatency";
import Traffic from "./Traffic";
import UploadChart from "./dataInsight/UploadChart";
import DownloadChart from "./dataInsight/DownloadChart";
// import ObjectChart from "./dataInsight/ObjectChart";
import Operations from "./Operations";
// import DataRebalance from "./DataRebalance";
import TierRankingPie from "./TierRankingPie";
import Alerts from "./Alerts";
import LoadingBars from "../../components/loader/LoadingBars";
import LoadingDots from "../../components/loader/LoadingDots";
import filesize from "filesize";
import type { TimeFrame } from "./types";

const updateBucketSizeInterval = 60 * 60 * 1000; // 1hour.

const consoleErrHandler = (err: Error) => {
  if (axios.isCancel(err)) {
    console.warn(err);
    return;
  }
  console.error(err);
};

const timeFrameName = (timeFrame: TimeFrame): string => {
  switch (timeFrame) {
    case "daily":
      return "Stats Summary - 24 hours";
    case "weekly":
      return "Stats Summary - 7 days";
    case "monthly":
      return "Stats Summary - 1 month";
    default:
      // shouldn't reach here.
      return "Stats Summary";
  }
};

const stripTransactions = (t: S3Transaction): ?S3Transaction => {
  if (!t.upload && !t.download) return null;
  t.identityId = "";
  t.accessKeyId = "";
  t.gatewayIP = "";
  delete t.processTime;
  return t;
};

const useTransactionsByTimeFrame = (
  timeFrame: TimeFrame,
  errHandler: Error => void
): ?Array<S3Transaction> => {
  const daily = useTransactions(
    oneDay,
    timeFrame === "daily" ? 5 * 60 * 1000 : null, // daily transactions will be updated every 5min.
    errHandler,
    stripTransactions
  );
  const weekly = useTransactions(
    oneWeek,
    timeFrame === "weekly" ? 24 * 60 * 60 * 1000 : null, // weekly transactions will be updated every day.
    errHandler,
    stripTransactions
  );
  const monthly = useTransactions(
    oneMonth,
    timeFrame === "monthly" ? 24 * 60 * 60 * 1000 : null, // monthly transactions will be updated every day.
    errHandler,
    stripTransactions
  );
  switch (timeFrame) {
    case "daily":
      return daily;
    case "weekly":
      return weekly;
    case "monthly":
      return monthly;
    default:
      // shouldn't reach here.
      return [];
  }
};

const useBucketSizeByTimeFrame = (
  timeFrame: TimeFrame,
  errHandler: Error => void
): ?BucketSizeMap => {
  const daily = useBucketSize(
    timeFrame === "daily" ? 5 * 60 * 1000 : null, // daily will be updated every 5min. interval
    oneDay, //range
    errHandler
  );
  const weekly = useBucketSize(
    timeFrame === "weekly" ? 24 * 60 * 60 * 1000 : null, // weekly will be updated every day.
    oneWeek,
    errHandler
  );
  const monthly = useBucketSize(
    timeFrame === "monthly" ? 24 * 60 * 60 * 1000 : null, // monthly will be updated every day.
    oneMonth,
    errHandler
  );
  switch (timeFrame) {
    case "daily":
      return daily;
    case "weekly":
      return weekly;
    case "monthly":
      return monthly;
    default:
      // shouldn't reach here.
      return null;
  }
};

export const Dashboard = (): Node => {
  const bucketSizeHistory = useBucketSize(
    updateBucketSizeInterval,
    oneDay,
    consoleErrHandler
  );
  const bucketSizes = useMemo<?Array<{ numbers: number, size: number }>>(() => {
    if (!bucketSizeHistory) return null;
    return Object.keys(bucketSizeHistory).map(bucket =>
      bucketSizeHistory[bucket].reduce((acc, cur) => {
        if (!acc) return cur;
        if (new Date(acc.time).getTime() > new Date(cur.time).getTime()) {
          return acc;
        }
        return cur;
      })
    );
  }, [bucketSizeHistory]);

  const contracts = useContracts(consoleErrHandler);
  // TODO: add tests
  const hostKeys = useMemo(
    () =>
      new Set(
        contracts
          .map(s => s.contractSets)
          .filter(Boolean)
          .map(sets =>
            sets
              .map(set => set.contracts)
              .filter(Boolean)
              .map(contracts => contracts.map(c => c.hostKey))
          )
          .flat(2)
      ),
    [contracts]
  );
  const availabilityZones = hostKeys.size;
  // TODO: render errors instead of printing them in console.
  const traffic = useTraffic(consoleErrHandler);
  // TODO: render a loading message instead of using this zero value if useMetrics returns null.
  const metrics = useMetrics(consoleErrHandler) || {
    gatewayIPs: new Set(),
    uploadLatency: 0,
    downloadLatency: 0,
    operations: 0
  };
  // const gatewayInfo = useGeolocations(metrics.gatewayIPs, consoleErrHandler);

  // TODO: add rest of timeframes 3m, 6m, 1y
  const [timeFrame, setTimeFrame] = useState<TimeFrame>("daily");

  const transactions = useTransactionsByTimeFrame(timeFrame, consoleErrHandler);
  const objectsPerTime = useBucketSizeByTimeFrame(timeFrame, consoleErrHandler);

  return (
    <>
      {/* <div className="row">
        <div className="col-12">
          <Portlet className="map">
            <HostsMap gatewayInfo={gatewayInfo} />
          </Portlet>
        </div>
      </div> */}
      <Row className="stats-number-row">
        <div className="stats-number-col">
          {bucketSizes ? (
            <h3 className="dashboard-value-size-1 text-center font-weight-600">
              {bucketSizes.length}
            </h3>
          ) : (
            <LoadingDots marginTop={"mt-0"} marginBottom={"mt-0"} />
          )}
          <span className="subtitle-gray">Buckets</span>
        </div>
        <div className="stats-number-col bordered">
          {bucketSizes ? (
            <h3 className="dashboard-value-size-1 text-center font-weight-600">
              {bucketSizes.reduce((acc, cur): number => acc + cur.numbers, 0)}
            </h3>
          ) : (
            <LoadingDots marginTop={"mt-0"} marginBottom={"mt-0"} />
          )}

          <span className="subtitle-gray">Total Objects</span>
        </div>
        <div className="stats-number-col bordered">
          {bucketSizes ? (
            <h3 className="dashboard-value-size-1 text-center main-text font-weight-600">
              {filesize(
                bucketSizes.reduce((acc, cur): number => acc + cur.size, 0),
                { standard: "iec" }
              )}
            </h3>
          ) : (
            <LoadingDots marginTop={"mt-0"} marginBottom={"mt-0"} />
          )}
          <span className="subtitle-gray">Total Storage</span>
        </div>
        <div className="stats-number-col bordered">
          <h3 className="dashboard-value-size-1 text-center font-weight-600">
            {metrics.gatewayIPs.size}
          </h3>
          <span className="subtitle-gray">Active Gateways</span>
        </div>
        <div className="stats-number-col bordered">
          <h3 className="dashboard-value-size-1 text-center font-weight-600">
            {availabilityZones}
          </h3>
          <span className="subtitle-gray">Availability Zones</span>
        </div>
      </Row>

      <Row>
        <Col xs={4}>
          <div className="card-dark-475">
            <div className="chart-tier-wrapper d-flex flex-column align-items-center">
              <h3 className="card-title-chart pl-4 pt-4 pr-4 pb-0 mt-0">
                Storage Tier Ranking
              </h3>
              {bucketSizes && <TierRankingPie number={bucketSizes.length} />}
            </div>
          </div>
        </Col>
        <Col xs={4}>
          {/* <div className="card-dark-225">
            <div className="p-4 d-flex flex-column justify-content-center">
              <DataRebalance title="Data Rebalance" />
            </div>
          </div> */}
          <div className="card-dark-225">
            <div className="p-4 d-flex flex-column text-center">
              <h3 className="card-title mb-175">GET Latency</h3>
              <GetLatency value={metrics.downloadLatency} />
            </div>
          </div>

          <div className="card-dark-225">
            <div className="p-4 d-flex flex-column justify-content-center">
              <Operations operations={metrics.operations} />
            </div>
          </div>
        </Col>
        <Col xs={4}>
          <div className="card-dark-225 p-4 d-flex flex-column">
            <h4 className="card-title mb-4 text-center">Daily Traffic</h4>

            {traffic ? (
              <div className="traffic-wrapper w-100">
                <Traffic
                  title="Outbound"
                  traffic={traffic.uploadSpeed}
                  transferred={traffic.uploadSize}
                />
                <Traffic
                  title="Inbound"
                  traffic={traffic.downloadSpeed}
                  transferred={traffic.downloadSize}
                />
              </div>
            ) : (
              <LoadingBars marginTop={"mt-4"} />
            )}
          </div>
          <div className="card-dark-225">
            <div className="p-4 d-flex flex-column text-center">
              <h3 className="card-title mb-175">Put Latency</h3>
              <PutLatency value={metrics.uploadLatency} />
            </div>
          </div>
          {/* <div className="card-dark-225">
            <div className="p-4 d-flex flex-column text-center">
              <h3 className="card-title mb-175">First Byte Latency</h3>
              <FirstByteLatency />
            </div>
          </div> */}
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="card-dark-225">
            <div className="chart-wrapper p-4 d-flex flex-column">
              <h3 className="card-title mb-5">Alerts</h3>
              <Alerts />
            </div>
          </div>
        </Col>
        {/* <Col xs={4}> */}
        {/* <div className="card-dark-225">
            <div className="p-4 d-flex flex-column text-center">
              <h3 className="card-title mb-175">GET Latency</h3>
              <GetLatency value={getLatency} />
            </div>
          </div> */}

        {/* <div className="card-dark-225">
            <div className="p-4 d-flex flex-column text-center">
              <h3 className="card-title mb-175">Put Latency</h3>
              <PutLatency value={putLatency} />
            </div>
          </div> */}
        {/* </Col> */}
      </Row>

      <Row className="wrapper-data-insight">
        <Col lg={12}>
          <div className="px-4 py-3 d-flex align-items-baseline data-insight-row">
            <h2 className="card-title mr-5">Data Insight</h2>
            <h3 className="stats-summary text-center">
              {timeFrameName(timeFrame)}
            </h3>
            <div>
              <span className="text-white mr-3">Last</span>
              <Button
                className={classNames("btn btn-time-dashboard mr-2", {
                  active: timeFrame === "daily"
                })}
                variant="contained"
                onClick={() => {
                  setTimeFrame("daily");
                }}
              >
                <span>24h</span>
              </Button>

              <Button
                className={classNames("btn btn-time-dashboard mr-2", {
                  active: timeFrame === "weekly"
                })}
                variant="contained"
                onClick={() => {
                  setTimeFrame("weekly");
                }}
              >
                <span>7d</span>
              </Button>

              <Button
                className={classNames("btn btn-time-dashboard mr-2", {
                  active: timeFrame === "monthly"
                })}
                variant="contained"
                onClick={() => {
                  setTimeFrame("monthly");
                }}
              >
                <span>1m</span>
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="card-dark mt-0 btr-radius-0 btl-radius-0">
            <div className="p-4 d-flex flex-column h-100">
              <div className="wrapper-total">
                <div className="d-flex flex-column text-center">
                  {transactions ? (
                    <FileSize
                      size={transactions
                        .map(t => t.upload || 0)
                        .reduce((acc, cur) => acc + cur, 0)}
                    />
                  ) : (
                    <LoadingDots marginTop={"mt-4"} marginBottom={"mb-3"} />
                  )}

                  <span className="subtitle-gray">Total Data Uploaded</span>
                </div>
                <div className="d-flex flex-column text-center bordered">
                  {transactions ? (
                    <FileSize
                      size={transactions
                        .map(t => t.download || 0)
                        .reduce((acc, cur) => acc + cur, 0)}
                    />
                  ) : (
                    <LoadingDots marginTop={"mt-4"} marginBottom={"mb-3"} />
                  )}
                  <span className="subtitle-gray">Total Data Downloaded</span>
                </div>
              </div>
            </div>
          </div>

          <div className="card-dark-280">
            <div className="chart-wrapper p-4 d-flex flex-column justify-content-center position-relative">
              <h3 className="card-title-chart">Data Download</h3>

              {transactions ? (
                <DownloadChart
                  timeFrame={timeFrame}
                  transactions={transactions}
                />
              ) : (
                <LoadingBars marginTop={"mt-5"} />
              )}
            </div>
          </div>

          <div className="card-dark-280">
            <div className="chart-wrapper p-4 d-flex flex-column justify-content-center position-relative">
              <h3 className="card-title-chart">Data Upload</h3>
              {transactions ? (
                <UploadChart
                  timeFrame={timeFrame}
                  transactions={transactions}
                />
              ) : (
                <LoadingBars marginTop={"mt-5"} />
              )}
            </div>
          </div>

          <div className="card-dark-280">
            <div className="chart-wrapper p-4 d-flex flex-column justify-content-center position-relative">
              <h3 className="card-title-chart">Number of Objects</h3>
              {objectsPerTime ? (
                <ObjectChart
                  timeFrame={timeFrame}
                  objectsPerTime={objectsPerTime}
                />
              ) : (
                <LoadingBars marginTop={"mt-5"} />
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
