// @flow
import React, { type Node } from "react";
import { Link, NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import { useIdentity } from "../../contexts";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import Settings from "../../../app/assets/aside/settings.svg";
// import Billing from "../../../app/assets/aside/billing.svg";

export const UserProfile = (): Node => {
  const identity = useIdentity();
  const height = "25px";

  return (
    <Dropdown
      className="kt-header__topbar-item kt-header__topbar-item--user"
      drop="down"
      alignRight
    >
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-user-profile"
      >
        <div
          className="kt-header__topbar-wrapper"
          data-toggle="dropdown"
          data-offset="10px,0px"
        >
          <img alt="" src="" />
          <span className="kt-header__topbar-icon">
            {/* TODO: Should get from currentUser */}
            <b>
              {identity
                .username()
                .charAt(0)
                .toUpperCase()}
            </b>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-sm">
        {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
        <div className="kt-user-card kt-user-card--skin-dark" style={{}}>
          <ul className="pl-0 mt-2 mb-2">
            <NavLink to="/settings" activeClassName="active">
              <li className="topbar-profile-item">
                <img
                  className="mr-5"
                  src={Settings}
                  alt="Settings"
                  height={height}
                />
                Account Settings
              </li>
            </NavLink>
            {/* <NavLink to="/billing" activeClassName="active">
              <li className="topbar-profile-item">
                <img
                  className="mr-5"
                  src={Billing}
                  alt="Billing"
                  height={height}
                />
                Billing
              </li>
            </NavLink> */}
            <li className="kt-user-card__badge">
              <div className="kt-notification__custom">
                <Link
                  to="/logout"
                  className="btn btn-label-brand btn-sm btn-bold w-100"
                  style={{ color: "white" }}
                >
                  Logout
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserProfile;
