/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./app/router/Routes";
import { ContextProvider } from "./app/contexts";
import { I18nProvider, LayoutSplashScreen, ThemeProvider } from "./_metronic";
import "@mdi/font/css/materialdesignicons.min.css";

export default function App({ store, persistor, basename }) {
  console.log(
    " _____ _               _ _ _ _        \n" +
      "|   __| |_ ___ ___ ___| | | |_|___ ___\n" +
      "|__   |  _| . |  _| -_| | | | |_ -| -_|\n" +
      "|_____|_| |___|_| |___|_____|_|___|___|\n" +
      "\n\n- Welcome to the StoreWise console! \n- We are always very grateful and open to receive your feedback be it tips, ideas, suggestions or any other comments with us at hello@storewise.tech \n- If you have an interest in data storage and distributed technologies and might be interested in becoming part of the team feel free to reach out at any time."
  );

  return (
    /* Provide Redux store */
    <Provider store={store} loading={<LayoutSplashScreen />}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor}>
        <ContextProvider>
          {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
          <React.Suspense fallback={<LayoutSplashScreen />}>
            {/* Override `basename` (e.g: `homepage` in `package.json`) */}
            <BrowserRouter basename={basename}>
              {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
              <LastLocationProvider>
                {/* Provide Metronic theme overrides. */}
                <ThemeProvider>
                  {/* Provide `react-intl` context synchronized with Redux state.  */}
                  <I18nProvider>
                    {/* Render routes with provided `Layout`. */}
                    <Routes />
                  </I18nProvider>
                </ThemeProvider>
              </LastLocationProvider>
            </BrowserRouter>
          </React.Suspense>
        </ContextProvider>
      </PersistGate>
    </Provider>
  );
}
