//@flow
import { useCallback } from "react";
import type { S3MetaDBClient } from "../../contexts/s3metadb";
import type { Object as S3Object, Container, FetchObjects } from "./types";
import { maxObjectsPerRequest } from "./constants";

export const useFetchObjects = (
  cli: ?S3MetaDBClient,
  bucket: string,
  objects: Container, // the objects already fetched.
  setObjects: Container => void
): FetchObjects =>
  useCallback(async (): Promise<boolean> => {
    if (!cli) return false;

    const res = await cli.listObjects(
      bucket,
      objects.token,
      maxObjectsPerRequest
    );
    setObjects({
      items: objects.items.concat(
        Object.keys(res.objects)
          .map((key: string): ?S3Object => {
            const obj = res.objects[key];
            if (obj.deleted) return null;

            const ver = obj.versions[obj.lastVersion];
            if (ver.deleted) return null;

            return {
              name: key,
              lastModified: new Date(ver.lastModified),
              size: ver.size
            };
          })
          .filter(Boolean)
      ),
      token: res.newToken || ""
    });
    return !res.newToken;
  }, [cli, bucket, objects, setObjects]);
