// @flow
import React, { useState, type Node, useCallback } from "react";
import type { Bucket } from "../hooks/buckets";
import {
  AllUsersID,
  Read,
  AuthenticatedUsersID,
  S3MetaDBClient
} from "../contexts/s3metadb";
import CheckBox from "./CheckBox";
import moment from "moment";
import { Link } from "react-router-dom";
import classNames from "classnames";

type Title = {
  name: string,
  width?: number
};

type Props = {
  className: string,
  enableSelection: boolean,
  renderActions: Bucket => Node,
  titles: Array<Title>,
  items: Array<Bucket>,
  onChange: (Array<string>) => void
};

const toStringACL = (bucket: Bucket): string => {
  let cannedACL = "Private";
  const acl = bucket.accessControlPolicy.accessControlList;
  if (S3MetaDBClient.hasPermission(acl[AllUsersID], Read)) {
    cannedACL = "Public Read";
  } else if (S3MetaDBClient.hasPermission(acl[AuthenticatedUsersID], Read)) {
    cannedACL = "Authenticated Read";
  }
  return cannedACL;
};

export const ListTable = ({
  className,
  enableSelection,
  renderActions = () => null,
  titles,
  items,
  onChange = () => {}
}: Props): Node => {
  const [selections, setSelections] = useState<Array<string>>([]);
  const updateSelections = useCallback(
    list => {
      onChange(list);
      setSelections(list);
    },
    [onChange, setSelections]
  );

  const handleBulkSelection = useCallback(
    (isSelected: boolean) => {
      if (!isSelected) {
        updateSelections([]);
      } else {
        updateSelections(items.map(b => b.name));
      }
    },
    [items, updateSelections]
  );
  const updateItemSelection = (name: string, isSelected: boolean) => {
    if (isSelected) {
      updateSelections([...selections, name]);
    } else {
      updateSelections(selections.filter(v => v !== name));
    }
  };

  const header = (
    <tr>
      {enableSelection && (
        <td>
          <CheckBox onChange={handleBulkSelection} />
        </td>
      )}
      {// TODO: calc width automatically by col count and
      // small|normal|big preferences.
      titles.map(({ name, width: _width }) => {
        return <td key={name}>{name}</td>;
      })}
      {renderActions && <td />}
    </tr>
  );
  const body = items.map((bucket: Bucket) => {
    const isSelected = selections.indexOf(bucket.name) !== -1;
    return (
      <tr
        className={classNames({ deletingbucket: bucket.deleted })}
        key={bucket.name}
      >
        {enableSelection && (
          <td>
            <CheckBox
              checked={isSelected}
              onChange={isSelected => {
                updateItemSelection(bucket.name, isSelected);
              }}
            />
          </td>
        )}
        <td>
          <Link
            className="kt-widget11__title bucket-name"
            to={`buckets/${bucket.name}`}
          >
            {bucket.name}
          </Link>
        </td>
        <td>{moment(bucket.creationDate).format("LLL")}</td>
        <td>{toStringACL(bucket)}</td>
        <td>Global</td>
        <td>General</td>
        <td>{renderActions(bucket)}</td>
      </tr>
    );
  });
  return (
    <div className="kt-widget11">
      <div className="table-responsive">
        <table className={`table ${className}`}>
          <thead>{header}</thead>
          <tbody className="striped buckets-tbody">{body}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ListTable;
