//@flow
import React, { type Node } from "react";
import Icon from "@mdi/react";
import { mdiAlert, mdiAlertOctagram } from "@mdi/js";

type Alert = {
  type: "info" | "error",
  time: string,
  message: string,
  status?: string
};

type AlertItemProps = Alert;

export const AlertItem = ({
  type,
  time,
  message,
  status
}: AlertItemProps): Node => {
  let icon;
  if (type === "info") {
    icon = <Icon path={mdiAlert} title="Info" size={1} color="#26aae1" />;
  } else {
    icon = (
      <Icon path={mdiAlertOctagram} title="Error" size={1} color="#bc3315" />
    );
  }
  return (
    <tr>
      <td>{icon}</td>
      <td>{time}</td>
      <td>{message}</td>
      <td>{status}</td>
    </tr>
  );
};

// Note: we can add static alerts.
const staticAlerts: Array<Alert> = [
  {
    type: "info", // type must be "info" or "error"
    time: "2021-02-01",
    message: "Each user can create one bucket during the private test."
  }
];

type Props = {
  alerts?: Array<Alert>
};

const Alerts = ({ alerts = staticAlerts }: Props): Node => (
  <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
    <div className="row">
      <div className="col-xl-12">
        <div className="kt-widget11">
          <div className="table-responsive">
            <table className="table table-alerts">
              <thead className="dashed">
                <tr>
                  <td />
                  <td>Time</td>
                  <td>Description</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody className="striped alerts-tbody">
                {alerts.map(({ type, time, message, status }) => (
                  <AlertItem
                    key={time + message}
                    type={type}
                    time={time}
                    message={message}
                    status={status}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Alerts;
