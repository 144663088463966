//@flow
import { useMemo } from "react";
import { oneDay, useTransactions } from "../index";
import type { S3Transaction } from "../../contexts/s3metadb";

export const updateInterval = 60 * 60 * 1000; // 1hour.

export type Traffic = {
  uploadSpeed: number, // bytes/sec.
  uploadSize: number, // bytes
  downloadSpeed: number, // bytes/sec.
  downloadSize: number // bytes
};

const useTraffic = (errHandler: Error => void): ?Traffic => {
  const transactions = useTransactions(oneDay, updateInterval, errHandler);
  return useMemo<?Traffic>(() => {
    if (!transactions) return null;

    let uploadSpeed = 0,
      uploadSize = 0,
      uploadCount = 0,
      downloadSpeed = 0,
      downloadSize = 0,
      downloadCount = 0;
    transactions.forEach((t: S3Transaction) => {
      // If the process time is not given, skip the transaction record.
      if (!t.processTime) return;
      // Note: processTime is in nano second, and we need to convert it in second.
      const processTime = t.processTime * Math.pow(10, -9);
      if (t.upload) {
        uploadSpeed +=
          (t.upload / processTime - uploadSpeed) / (uploadCount + 1);
        uploadSize += t.upload;
        ++uploadCount;
      }
      if (t.download) {
        downloadSpeed +=
          (t.download / processTime - downloadSpeed) / (downloadCount + 1);
        downloadSize += t.download;
        ++downloadCount;
      }
    });
    return { uploadSpeed, uploadSize, downloadSpeed, downloadSize };
  }, [transactions]);
};

export default useTraffic;
