//@flow
import React, { type Node } from "react";
import filesize from "filesize";

type Props = {
  title: string,
  traffic: number, //bytes
  transferred: number //bytes
};

const Traffic = ({ title, traffic, transferred }: Props): Node => {
  // To obtain value and unit splitted for styling
  const fileSizeTraffic = filesize(traffic, {
    output: "object",
    standard: "iec"
  });
  const fileSizeTransferred = filesize(transferred, {
    output: "object",
    standard: "iec"
  });

  return (
    <div className="d-flex flex-column">
      <h4 className="traffic-type mb-2">{title}</h4>
      <h3 className="traffic-value">
        {fileSizeTraffic.value.toFixed(1)}
        <span className="unit">&nbsp;{`${fileSizeTraffic.symbol}/s`}</span>
      </h3>
      <div className="mt-4 font-weight-500">
        <h4 className="card-title-small mb-1">Total Transferred</h4>
        <span>
          {fileSizeTransferred.value}
          <span className="unit">&nbsp;{fileSizeTransferred.symbol}</span>
        </span>
      </div>
    </div>
  );
};

export default Traffic;
