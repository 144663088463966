import React from "react";
import SemiCircleProgressBar from "react-progressbar-semicircle";

const Operations = ({ operations }) => {
  const maxValue = 15;
  let percentageValue = (operations / maxValue) * 100;

  if (operations > maxValue) {
    percentageValue = 100;
  }

  if (operations < 1 && operations >= 0.1) {
    operations = Number.parseFloat(operations).toFixed(1);
  } else if (operations < 0.1 && operations >= 0.01) {
    operations = Number.parseFloat(operations).toFixed(2);
  } else if (operations < 0.01 && operations >= 0.001) {
    operations = Number.parseFloat(operations).toFixed(3);
  }

  return (
    <div className="d-flex flex-column">
      <h3 className="card-title mb-4 pb-1 text-center">Operations</h3>
      {/* Still need to find out a good maximum value to determine percentages */}
      <SemiCircleProgressBar
        background="#384d5a"
        percentage={percentageValue}
        stroke="#26aae1"
        strokeWidth={18}
      />
      <div className="d-flex flex-column text-center wrapper-op">
        <span className="dashboard-value-size-1">{operations}</span>
        <span className="subtitle-gray">OP/min</span>
      </div>
    </div>
  );
};

export default Operations;
