import React from "react";

export const LoadingBars = ({ marginTop }) => {
  return (
    <div className={`container ${marginTop}`}>
      <div className="loader">
        <div className="note-0"></div>
        <div className="note-1"></div>
        <div className="note-2"></div>
        <div className="note-3"></div>
        <div className="note-4"></div>
      </div>
      <div className="status">Loading...</div>
    </div>
  );
};

export default LoadingBars;
