// @flow
import React, { useState, useMemo, type Node } from "react";
import ListTableBucket from "../../../components/ListTableBucket";
import StickyPortlet from "../../../components/StickyPortletBuckets";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { useBuckets } from "../../../hooks";
import type { Bucket } from "../../../hooks/buckets";
import orphan from "../../../styles/orphan";
import NewBucketDialog from "./NewBucketDialog";
import DeleteBucketError from "./errors/DeleteBucketError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faFolderPlus } from "@fortawesome/free-solid-svg-icons";
import { Fade } from "@material-ui/core";
import { Button } from "react-bootstrap";

const titles = [
  {
    name: "Name"
  },
  {
    name: "Creation Date"
  },
  {
    name: "Access"
  },
  {
    name: "Region"
  },
  {
    name: "Tier"
  }
];

const compareList = (a: Bucket, b: Bucket): number => {
  if (a.name > b.name) return -1;
  if (a.name < b.name) return 1;
  return 0;
};

export const Buckets = (): Node => {
  const [selectedList, setSelectedList] = useState([]);
  const [isNewBucketDialogOpen, setIsNewBucketDialogOpen] = useState(false);
  const [isDeleteErrorDialogOpen, setDeleteErrorDialogOpen] = useState(false);
  const [
    isDeleteConfirmationDialogOpen,
    setIsDeleteConfirmationDialogOpen
  ] = useState(false);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState({
    question: "",
    details: ""
  });
  const [error, setError] = useState<?Error>(null);
  const buckets = useBuckets(setError);

  const removedBucketError = (err: Error) => {
    setError(err);
    setDeleteErrorDialogOpen(true);
    setIsDeleteConfirmationDialogOpen(false);
  };

  const showDeleteConfirmationDialog = (ids: Array<string>) => {
    setDeleteConfirmationText({
      question:
        "You are about to delete following bucket(s). Do you confirm to proceed?",
      details: ids.join(", ")
    });
    setIsDeleteConfirmationDialogOpen(true);
  };

  const handleDeleteConfirmationDialogClose = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      setIsDeleteConfirmationDialogOpen(false);
    } else {
      buckets
        .remove(...selectedList)
        .then(() => setIsDeleteConfirmationDialogOpen(false))
        .catch(err => removedBucketError(err.message));
    }
  };

  const handleBucketDelete = (id: string) => {
    setSelectedList([id]);
    showDeleteConfirmationDialog([id]);
  };

  return (
    <div>
      <StickyPortlet
        title="Buckets"
        subtitle={buckets.items.length + " buckets found"}
        actions={
          <>
            {!!error && (
              <span className="bucket-error-message">{error.message}</span>
            )}
            {selectedList.length > 0 && (
              <Fade in={true}>
                <Button
                  variant="outlined"
                  size="medium"
                  style={{
                    color: "rgba(255, 255, 255, 0.5)",
                    borderColor: "rgba(255, 255, 255, 0.5)",
                    marginRight: 10
                  }}
                  onClick={() => {
                    showDeleteConfirmationDialog(selectedList);
                  }}
                >
                  Delete Selected
                </Button>
              </Fade>
            )}
            <Button
              className="btn btn-sw-primary"
              variant="contained"
              onClick={() => {
                setIsNewBucketDialogOpen(true);
              }}
            >
              <FontAwesomeIcon size="lg" icon={faFolderPlus} />
              <span className="ml-2">New Bucket</span>
            </Button>
          </>
        }
        content={
          <ListTableBucket
            className="table-buckets"
            renderActions={item => {
              return (
                <FontAwesomeIcon
                  className="remove-icon"
                  size="lg"
                  icon={faTrashAlt}
                  style={{ ...orphan.cursorPointer }}
                  onClick={() => {
                    handleBucketDelete(item.name);
                  }}
                />
              );
            }}
            enableSelection={true}
            titles={titles}
            items={useMemo(() => buckets.items.sort(compareList), [buckets])}
            onChange={setSelectedList}
          />
        }
        onReload={() => buckets.fetch().catch(setError)}
      />

      <NewBucketDialog
        open={isNewBucketDialogOpen}
        handleSubmit={buckets.create}
        handleClose={() => {
          setIsNewBucketDialogOpen(false);
        }}
        handleOpen={() => {
          setIsNewBucketDialogOpen(true);
        }}
      />

      <ConfirmDialog
        open={isDeleteConfirmationDialogOpen}
        title="Confirm to Delete Buckets"
        question={deleteConfirmationText.question}
        details={deleteConfirmationText.details}
        handleClose={handleDeleteConfirmationDialogClose}
      />

      <DeleteBucketError
        open={isDeleteErrorDialogOpen}
        handleClose={() => {
          setDeleteErrorDialogOpen(false);
        }}
        message={error ? error.message : ""}
      />
    </div>
  );
};

export default Buckets;
