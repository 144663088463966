// @flow
import { useEffect, useState } from "react";
import SwaggerClient from "swagger-client";
import type { Identity } from "../identity/types";
import {
  AllUsersID,
  AuthenticatedUsersID,
  CanonicalUserType,
  FullControl,
  GroupType,
  Read
} from "./constants";
import specPath from "./s3-metadata-swagger.yaml";
import type { BucketSize } from "./swagger";
import type {
  APIClient,
  S3BucketInfo,
  S3Bucket,
  ListObjectsResult,
  ListTransactionsResult,
  ContractStatus
} from "./types";

export let InvalidCannedACL: Error;
InvalidCannedACL = new Error("Invalid Canned ACL");

// auth returns a requestInterceptor that embeds the given token to Authorization header.
const auth = (token: string): Function => req => {
  req.headers.Authorization = `Bearer ${token}`;
  return req;
};

// Client is a swagger client for s3-metadata service.
export class S3MetaDBClient {
  client: APIClient;
  identity: Identity;

  constructor(client: APIClient, identity: Identity) {
    this.client = client;
    this.identity = identity;
  }

  // createBucket creates a new bucket that has a given name and is initialized with a given canned ACL.
  async createBucket(
    name: string,
    acl: string,
    versioning: boolean = false
  ): Promise<S3Bucket> {
    const creationDate = new Date().toISOString();
    const owner = {
      displayName: this.identity.email(),
      id: this.identity.id()
    };

    let accessControlList = {};
    accessControlList[this.identity.id()] = [
      {
        grantee: {
          xsiType: CanonicalUserType,
          id: this.identity.id(),
          displayName: this.identity.email()
        },
        permission: FullControl
      }
    ];
    switch (acl) {
      case "private":
        break;
      case "public-read":
        accessControlList[AllUsersID] = [
          {
            grantee: {
              xsiType: GroupType,
              uri: AllUsersID
            },
            permission: Read
          }
        ];
        break;
      case "authenticated-read":
        accessControlList[AuthenticatedUsersID] = [
          {
            grantee: {
              xsiType: GroupType,
              uri: AuthenticatedUsersID
            },
            permission: Read
          }
        ];
        break;
      default:
        throw InvalidCannedACL;
    }
    const Bucket: S3Bucket = {
      deleted: false,
      name,
      creationDate,
      accessControlPolicy: {
        owner,
        accessControlList
      },
      locationConstraint: "",
      versioningConfiguration: { status: "" }
    };
    if (versioning) {
      Bucket.versioningConfiguration = {
        status: "Enabled"
      };
    }

    await this.putBucket(Bucket);
    return Bucket;
  }

  // listBuckets returns a list of existing buckets.
  async listBuckets(): Promise<Array<S3BucketInfo>> {
    const res = await this.client.get_buckets(
      {},
      { requestInterceptor: auth(await this.identity.idToken()) }
    );
    return res.body
      ? res.body
          .filter(
            (b: S3BucketInfo): boolean => b.owner.id === this.identity.id()
          )
          .sort(
            (lhs: S3BucketInfo, rhs: S3BucketInfo): number =>
              new Date(lhs.creationDate) - new Date(rhs.creationDate)
          )
      : [];
  }

  // getBucket retrieves bucket metadata associated with the given bucket name.
  async getBucket(name: string): Promise<S3Bucket> {
    const res = await this.client.get_bucket__name_(
      { name },
      { requestInterceptor: auth(await this.identity.idToken()) }
    );
    return res.body;
  }

  // putBucket stores the given bucket metadata.
  async putBucket(b: S3Bucket): Promise<void> {
    await this.client.put_bucket__name_(
      { name: b.name, Bucket: b },
      { requestInterceptor: auth(await this.identity.idToken()) }
    );
  }

  // deleteBucket deletes a bucket.
  async deleteBucket(name: string): Promise<void> {
    await this.client.delete_bucket__name_(
      { name },
      { requestInterceptor: auth(await this.identity.idToken()) }
    );
  }

  // getBucketSize retrieves bucket size logs for a bucket.
  async getBucketSize(
    name: string,
    start: Date,
    token?: string
  ): Promise<BucketSize> {
    const res = await this.client.get_bucket__name__size(
      {
        name,
        start: start.toISOString(),
        token
      },
      { requestInterceptor: auth(await this.identity.idToken()) }
    );
    return res.body || {};
  }

  // listObjects retrieves objects using token and max keys.
  async listObjects(
    bucket: string,
    token: ?string,
    maxKeys: number = 1000
  ): Promise<ListObjectsResult> {
    const res = await this.client.get_objects_continuation__bucket_(
      {
        bucket,
        token,
        maxKeys: maxKeys.toString()
      },
      { requestInterceptor: auth(await this.identity.idToken()) }
    );
    return res.body ? res.body : { objects: {}, newToken: null };
  }

  // listTransactions retrieves transactions using key and max keys.
  async listTransactions(
    start: Date,
    key?: string,
    maxKeys: number = 1000
  ): Promise<ListTransactionsResult> {
    const res = await this.client.get_transaction(
      {
        start: start.toISOString(),
        key,
        maxKeys: maxKeys.toString()
      },
      { requestInterceptor: auth(await this.identity.idToken()) }
    );
    return res.body ? { transactions: [], ...res.body } : { transactions: [] };
  }

  // getContractStatus returns the user's contract formation status.
  async getContractStatus(): Promise<Array<ContractStatus>> {
    const res = await this.client.get_status(
      {},
      { requestInterceptor: auth(await this.identity.idToken()) }
    );
    return Array.isArray(res.body) ? res.body : [];
  }

  static hasPermission(
    permissions: ?Array<{ permission: string }>,
    target: string
  ): boolean {
    return (
      !!permissions &&
      (!!permissions.find(v => v.permission === FullControl) ||
        !!permissions.find(v => v.permission === target))
    );
  }
}

// useS3MetaDBClient asynchronously creates a new API client that uses the given token.
export const useS3MetaDBClient = (identity: Identity): ?S3MetaDBClient => {
  const [client, setClient] = useState();
  useEffect(() => {
    SwaggerClient({ url: specPath })
      .then(cli => {
        cli.spec.basePath = process.env.REACT_APP_API_BASE_PATH;
        setClient(new S3MetaDBClient(cli.apis.default, identity));
      })
      // TODO: error handling.
      .catch(console.error);
  }, [identity]);
  return client;
};
