// @flow

import React, { type Node } from "react";
import Icon from "@mdi/react";
import { mdiAlert } from "@mdi/js";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from "@material-ui/core";

type Props = {
  open: boolean,
  handleClose: () => void,
  message: string
};

const Error = ({ open, handleClose, message }: Props): Node => (
  <div>
    <Dialog id="bucket-creation" open={open} onClose={handleClose}>
      <DialogContent className="mt-3">
        <div className="text-center">
          <Icon path={mdiAlert} title="Info" size={2} color="#26aae1" />
          <h6 className="pb-3 pt-4 d-flex flex-column">
            <div className="mb-2">{message}</div>
          </h6>
        </div>
      </DialogContent>
      <DialogActions className="mb-3 mr-3">
        <Button
          className="cancel-btn-dialog"
          onClick={handleClose}
          color="primary"
        >
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);

export default Error;
