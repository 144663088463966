import React, { Component } from "react";

class CheckBox extends Component {
  handleChange(e) {
    const { onChange } = this.props;
    onChange && onChange(e.target.checked, this.props);
  }

  render() {
    const { name, description, checked } = this.props;

    return (
      <label className="kt-checkbox">
        <input
          type="checkbox"
          checked={checked}
          onChange={this.handleChange.bind(this)}
        />
        {description && (
          <div>
            <div className="kt-font-bold">{name}</div>
            <div className="mt-1" style={{ fontSize: 12 }}>
              {description}
            </div>
          </div>
        )}
        {!description && name && <div>{name}</div>}
        <span></span>
      </label>
    );
  }
}

export default CheckBox;
