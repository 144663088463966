// @flow
import md5 from "blueimp-md5";
import generatePassword from "password-generator";
import { useCallback } from "react";
import logger from "../../../old-console/src/logger";
import type {
  AddCredential,
  Credential,
  CredentialsUpdater,
  RemoveCredential
} from "./types";

export let InvalidTokenError: Error;
InvalidTokenError = new Error("Invalid token");

// useAddCredential returns a function that creates new credentials.
export const useAddCredential = (
  id: string,
  credentials: Array<Credential>,
  setCredentials: CredentialsUpdater
): AddCredential =>
  useCallback(() => {
    if (id === "") throw InvalidTokenError;

    const secretAccessKey = generatePassword(40);
    const accessKeyId = "AK" + md5(id, secretAccessKey).toUpperCase();
    logger.verbose(`Registering a new access key pair ${accessKeyId}`);
    setCredentials(
      credentials.concat({
        accessKeyId,
        creationTime: new Date().toISOString()
      })
    );
    return { accessKeyId, secretAccessKey };
  }, [id, credentials, setCredentials]);

// useRemoveCredential returns a function that removes a credential.
export const useRemoveCredential = (
  credentials: Array<Credential>,
  setCredentials: CredentialsUpdater
): RemoveCredential =>
  useCallback(
    (...accessKeyIDs: Array<string>): void => {
      logger.verbose(`Removing access key IDs ${accessKeyIDs.join(", ")}`);

      const removed = new Set(accessKeyIDs);
      setCredentials(
        credentials.filter(cred => !removed.has(cred.accessKeyId))
      );
    },
    [credentials, setCredentials]
  );
