import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import {
  TextField,
  InputAdornment,
  ClickAwayListener,
  Tooltip
} from "@material-ui/core";

export class CopyableFieldAccessKey extends Component {
  constructor(props) {
    super(props);
    this.state = { showCopied: false };
  }

  handleTooltipClose() {
    this.setState({ showCopied: false });
  }

  handleTooltipOpen() {
    this.setState({ showCopied: true });
  }

  render() {
    const { value } = this.props;

    return (
      <ClickAwayListener onClickAway={this.handleTooltipClose.bind(this)}>
        <div className="access-key-field">
          <Tooltip
            PopperProps={{
              disablePortal: true
            }}
            placement="bottom"
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={this.state.showCopied}
            onClose={this.handleTooltipClose.bind(this)}
            title="Copied"
            arrow
          >
            <CopyToClipboard spellCheck="false" text={value}>
              <TextField
                onClick={this.handleTooltipOpen.bind(this)}
                InputLabelProps={{ style: { color: "rgba(255,255,255,0.6)" } }}
                inputProps={{
                  style: {
                    cursor: "pointer",
                    fontSize: 14,
                    fontWeight: "500"
                  }
                }}
                margintop="0"
                marginbottom="0"
                value={value}
                readOnly
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ cursor: "pointer" }}
                    >
                      <FontAwesomeIcon size="lg" icon={faClipboard} />
                    </InputAdornment>
                  )
                }}
              />
            </CopyToClipboard>
          </Tooltip>
        </div>
      </ClickAwayListener>
    );
  }
}

export default CopyableFieldAccessKey;
