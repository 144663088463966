// @flow

import React, { type Node } from "react";
import ReactSpeedometer from "react-d3-speedometer";

type Props = {
  value: number
};

const PutLatency = ({ value }: Props): Node => {
  let unit = "s";
  let maxValue = 100;

  if (value > 60) {
    value = Math.round(value / 60);
    unit = "min";
    maxValue = 15;
  } else if (value < 1 && value > 0.1) {
    value = Math.round(value * Math.pow(10, 3));
    unit = "ms";
    maxValue = 1000;
  } else if (value <= 0.1) {
    value = parseFloat(value.toFixed(3));
  } else {
    value = Math.round(value);
  }

  const valueString = value + " " + unit;

  return (
    <ReactSpeedometer
      value={value}
      needleColor="#26aae1"
      needleTransitionDuration={4000}
      maxValue={maxValue}
      height={250}
      width={250}
      segments={1}
      needleHeightRatio={0.7}
      startColor={"#384d5a"}
      needleTransition="easeElastic"
      currentValueText={valueString}
      currentValuePlaceholderStyle={"#{value}"}
      ringWidth={19}
      // customSegmentStops={"#{value}"}
      textColor={"#fff"}
    />
  );
};

export default PutLatency;
