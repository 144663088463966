//@flow
import { useMemo } from "react";
import { fiveMin, useTransactions } from "../index";

export const updateInterval = 5 * 60 * 1000; // 5min.

export type Metrics = {
  gatewayIPs: Set<string>,
  uploadLatency: number,
  downloadLatency: number,
  operations: number // op/min
};

const useMetrics = (errHandler: Error => void): ?Metrics => {
  const transactions = useTransactions(fiveMin, updateInterval, errHandler);
  return useMemo<?Metrics>(() => {
    if (!transactions) return null;

    let gatewayIPs = new Set<string>(),
      uploadLatency = 0,
      uploadCount = 0,
      downloadLatency = 0,
      downloadCount = 0,
      operations = 0;
    transactions.forEach(t => {
      gatewayIPs.add(t.gatewayIP);
      // If the process time is not given, skip the transaction record.
      if (!t.processTime) return;
      // Note: processTime is in nano second, and we need to convert it in second.
      const processTime = t.processTime * Math.pow(10, -9);
      ++operations;
      if (t.upload) {
        uploadLatency += (processTime - uploadLatency) / (uploadCount + 1);
        ++uploadCount;
      }
      if (t.download) {
        downloadLatency +=
          (processTime - downloadLatency) / (downloadCount + 1);
        ++downloadCount;
      }
    });
    return {
      gatewayIPs,
      uploadLatency,
      downloadLatency,
      operations: operations / 5 // op/min.
    };
  }, [transactions]);
};

export default useMetrics;
