// @flow

import jwtDecode from "jwt-decode";
import {
  CognitoRegion,
  IdentityPoolId,
  UserPoolClientId,
  UserPoolId
} from "../../constants";
import type { Identity } from "./types";
import Amplify, { Auth } from "aws-amplify";

Amplify.configure({
  Auth: {
    identityPoolId: IdentityPoolId,
    region: CognitoRegion,
    userPoolId: UserPoolId,
    userPoolWebClientId: UserPoolClientId
  }
});

type Attributes = {
  sub?: string,
  "cognito:username"?: string,
  email?: string,
  given_name?: string,
  family_name?: string
};

class AmplifyIdentity implements Identity {
  attrs: Attributes;

  constructor(attrs: Attributes = {}) {
    this.attrs = attrs;
  }

  async idToken(): Promise<string> {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
  }

  id(): string {
    return this.attrs.sub || "";
  }

  username(): string {
    return this.attrs["cognito:username"] || "";
  }

  email(): string {
    return this.attrs.email || "";
  }

  givenName(): string {
    return this.attrs.given_name || "";
  }

  familyName(): string {
    return this.attrs.family_name || "";
  }

  async changeName(given_name: string, family_name: string): Promise<void> {
    await Auth.updateUserAttributes(await Auth.currentUserPoolUser(), {
      given_name,
      family_name
    });
    this.attrs.given_name = given_name;
    this.attrs.family_name = family_name;
  }

  async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<void> {
    await Auth.changePassword(
      await Auth.currentUserPoolUser(),
      oldPassword,
      newPassword
    );
  }

  async logout(): Promise<void> {
    try {
      await Auth.signOut();
    } finally {
      window.location.href = "/";
    }
  }

  static async init(): Promise<AmplifyIdentity> {
    return Promise.resolve<AmplifyIdentity>(
      createAmplifyIdentity(await Auth.currentUserPoolUser())
    );
  }

  static async login(
    username: string,
    password: string
  ): Promise<AmplifyIdentity> {
    return Promise.resolve<AmplifyIdentity>(
      createAmplifyIdentity(await Auth.signIn(username, password))
    );
  }

  static async signup(
    username: string,
    password: string,
    email: string
  ): Promise<void> {
    await Auth.signUp({
      username,
      password,
      attributes: { email }
    });
  }

  static async resendVerificationCode(username: string): Promise<void> {
    await Auth.resendSignUp(username);
  }
}

const createAmplifyIdentity = (user: any): AmplifyIdentity =>
  new AmplifyIdentity(
    jwtDecode(
      user
        .getSignInUserSession()
        .getIdToken()
        .getJwtToken()
    )
  );

export default AmplifyIdentity;
