//@flow
import { useEffect } from "react";
import { useS3MetaDB } from "../../contexts";
import useCache from "../cache";
import type { ContractStatus } from "../../contexts/s3metadb";

import { contractsStorageKey, DefaultTTL } from "./constants";

export const useContracts = (
  errHandler: Error => void
): Array<ContractStatus> => {
  const [contracts, setContracts] = useCache<Array<ContractStatus>>(
    contractsStorageKey,
    [],
    DefaultTTL
  );

  const cli = useS3MetaDB();
  useEffect(() => {
    if (!cli) return;
    cli
      .getContractStatus()
      .then(setContracts)
      .catch(errHandler);
  }, [cli, setContracts, errHandler]);

  return contracts;
};

export default useContracts;
