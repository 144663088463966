import React from "react";

export const LoadingDots = ({ marginTop, marginBottom }) => {
  return (
    <div className={`container text-center ${marginTop} ${marginBottom}`}>
      <div className="sp sp-3balls"></div>
    </div>
  );
};

export default LoadingDots;
