import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from "@material-ui/core";
import CopyableField from "../../../components/CopyableField";
// eslint-disable-next-line import/no-unresolved
import CsvDownloader from "react-csv-downloader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faFileDownload } from "@fortawesome/free-solid-svg-icons";

const columns = [
  {
    id: "first",
    displayName: "First column"
  },
  {
    id: "second",
    displayName: "Second column"
  }
];

// const datas = [{
//   first: 'foo',
//   second: 'bar'
// }, {
//   first: 'foobar',
//   second: 'foobar'
// }];

function FormDialog({ handleClose, accessKeyId, accessKeySecret, open }) {
  const csv = [
    {
      first: "access key",
      second: "access key secret"
    },
    {
      first: accessKeyId,
      second: accessKeySecret
    }
  ];

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle className="text-white">
        <FontAwesomeIcon size="lg" icon={faKey} />
        <span className="ml-2">A New Access Key Created</span>
      </DialogTitle>
      <DialogContent className="pb-4">
        <div>
          <CopyableField name="Access Key" value={accessKeyId} />
        </div>
        <div className="mt-2">
          <CopyableField name="Access Key Secret" value={accessKeySecret} />
        </div>
        <div className="download-text mt-3">
          Download the key file now, which contains your new access key. If you
          do not download the key file now, you will not be able to retrieve
          your secret access key again. When using the access keys for API
          access to the StoreWise service, the service endpoint is
          https://sias3.storewise.tech/
        </div>
      </DialogContent>
      <DialogActions className="mb-3 mr-3">
        <Button
          className="cancel-btn-dialog"
          onClick={handleClose}
          color="primary"
        >
          Close
        </Button>
        <CsvDownloader
          filename="credentials"
          separator=";"
          columns={columns}
          datas={csv}
        >
          <Button
            className="cta-btn-dialog"
            onClick={handleClose}
            color="primary"
          >
            <FontAwesomeIcon size="lg" icon={faFileDownload} />
            <span className="ml-2">Download</span>
          </Button>
        </CsvDownloader>
      </DialogActions>
    </Dialog>
  );
}

export default FormDialog;
