// @flow
import React, { type Node } from "react";
import { IdentityProvider } from "./identity";
import { S3MetaDBProvider } from "./s3metadb";

export { useIdentity, useIdentityManager } from "./identity";
export { useS3MetaDB, S3MetaDBClient } from "./s3metadb";

type Props = {
  children?: Node
};

export const ContextProvider = ({ children }: Props): Node => (
  <IdentityProvider>
    <S3MetaDBProvider>{children}</S3MetaDBProvider>
  </IdentityProvider>
);
