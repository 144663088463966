import React, { createContext } from "react";

const Context = createContext();

const groupIndex = 0;

export default function RadioGroup({ onChange, children }) {
  return (
    <Context.Provider
      value={{
        groupName: groupIndex + "-rg",
        onChange
      }}
    >
      <div className="kt-radio-list">{children}</div>
    </Context.Provider>
  );
}

export function Radio({ name, value, description }) {
  return (
    <Context.Consumer>
      {({ groupName, onChange }) => (
        <label className="kt-radio">
          <input
            type="radio"
            name={groupName}
            value={value}
            onChange={() => {
              onChange(value);
            }}
            defaultChecked={value === "private"}
          />
          {description && (
            <div>
              <div className="kt-font-bold">{name}</div>
              <div className="mt-1" style={{ fontSize: 12 }}>
                {description}
              </div>
            </div>
          )}
          {!description && name && <div>{name}</div>}
          <span></span>
        </label>
      )}
    </Context.Consumer>
  );
}
