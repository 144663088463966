import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export function Error404() {
  const history = useHistory();
  const goHomepage = () => history.push("/#");

  return (
    <div className="error-page-bg">
      <div className="error-wrapper">
        <h6 className="main-title-error">We apologize for the inconveniece.</h6>
        <span className="error-message">
          The requested page cannot be found.
        </span>
        <Button
          className="btn btn-sw-cta-return mt-3"
          onClick={goHomepage}
          type="button"
        >
          <div className="return-wrapper">
            <FontAwesomeIcon icon={faArrowLeft} />
            <span className="ml-3">Return</span>
          </div>
        </Button>
      </div>
    </div>
  );
}
