// @flow
import { useEffect } from "react";
import { useCache } from "../index";
import { useS3MetaDB } from "../../contexts";
import { bucketStorageKey, DefaultTTL } from "./constants";
import { useCreateBucket, useFetchBuckets, useRemoveBucket } from "./internal";
import type { Buckets, Bucket } from "./types";

// useBuckets returns Buckets object that has items, which is a list of bucket information,
// createBucket function, and removeBucket function.
const useBuckets = (errHandler: Error => void): Buckets => {
  const [buckets, setBuckets] = useCache<Array<Bucket>>(
    bucketStorageKey,
    [],
    DefaultTTL
  );

  const cli = useS3MetaDB();
  const fetch = useFetchBuckets(cli, setBuckets);
  const create = useCreateBucket(cli, buckets, setBuckets);
  const remove = useRemoveBucket(cli, buckets, setBuckets);
  useEffect(() => {
    // if already buckets have items, skip reloading bucket info.
    if (buckets.length) return;
    fetch().catch(errHandler);
  }, [buckets.length, fetch, errHandler]);

  return { items: buckets, fetch, create, remove };
};

export default useBuckets;
