import React, { useState } from "react";
// import { Redirect } from "react-router-dom";
import "../../../_metronic/_assets/sass/pages/login/login-4.scss";
import { Button, Alert } from "react-bootstrap";
import Logo from "../../assets/storeWiseText.svg";

function ForgotPassword({ setForgotPassword }) {
  const [email, setEmail] = useState("");

  const [validationMessage, setPassValidationMessage] = useState(""); // password validation error message
  const [loading, setLoading] = useState(false); // general flag for loading state
  // const [success, setSuccess] = useState(false);  // general flag for successful registration => check email

  // const params = {
  //   email: email,
  // };

  const validateEmail = () => {
    setLoading(true);
    if (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      // TODO: do something?
    } else {
      setLoading(false);
      setPassValidationMessage("Email is not valid");
    }
  };

  return (
    <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
      <div
        className="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v4 kt-login--signin"
        id="kt_login"
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
            <div className="kt-login__container">
              <div className="kt-login__logo">
                <a href="/">
                  <img src={Logo} height="60" alt="StoreWise Logo" />
                </a>
              </div>
              <div className="kt-login__signin">
                <form className="kt-form" autoComplete={"off"}>
                  <div className="input-group">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      name="email"
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="kt-login__actions">
                    {validationMessage.length > 0 ? (
                      <Alert variant="warning">{validationMessage}</Alert>
                    ) : (
                      ""
                    )}
                    {/* {success ? <Alert variant="success">Your password was successfully reset. Please check your email!</Alert> : ''} */}
                    <Button
                      type="submit"
                      className="btn btn-sw-cta-sign-in"
                      ariant="contained"
                      onClick={validateEmail}
                    >
                      {loading
                        ? "Resetting your password..."
                        : "Reset Password"}
                    </Button>
                  </div>
                  <div className="row p-0 mr-0 ml-0 kt-login__extra">
                    <div className="col p-0 kt-align-right">
                      <button
                        onClick={() => setForgotPassword(false)}
                        id="kt_login_forgot"
                        className="kt-login__link mb-0"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                  <input type="hidden" value="prayer" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
