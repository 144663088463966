// @flow
import { useCallback } from "react";
import type { S3MetaDBClient } from "../../contexts/s3metadb";
import type { Bucket, FetchBucket, CreateBucket, RemoveBucket } from "./types";

const bucketNameRegex = /^[a-z0-9][a-z0-9.-]*[a-z0-9.]$/;

export let InvalidBucketNameError: Error;
InvalidBucketNameError = new Error("Must be valid DNS name.");

export let BucketAlreadyExistsError: Error;
BucketAlreadyExistsError = new Error("Bucket already exists.");

// useFetchBuckets fetches bucket information with useEffect and sets them with setBuckets.
export const useFetchBuckets = (
  cli: ?S3MetaDBClient,
  setBuckets: (Array<Bucket>) => void
): FetchBucket =>
  useCallback(async () => {
    if (!cli) return;

    const infos = await cli.listBuckets();
    setBuckets(await Promise.all(infos.map(info => cli.getBucket(info.name))));
  }, [cli, setBuckets]);

// useCreateBucket returns a function that creates a bucket.
export const useCreateBucket = (
  cli: ?S3MetaDBClient,
  buckets: Array<Bucket>,
  setBuckets: (Array<Bucket>) => void
): CreateBucket =>
  useCallback(
    async (name, acl, versioning) => {
      if (!cli) return;

      if (!name.match(bucketNameRegex)) {
        throw InvalidBucketNameError;
      }
      if (buckets.find(b => b.name === name)) {
        throw BucketAlreadyExistsError;
      }

      const bucket = await cli.createBucket(name, acl, versioning);
      setBuckets([...buckets, bucket]);
    },
    [cli, buckets, setBuckets]
  );

// useRemoveBucket returns a function that removes a bucket.
export const useRemoveBucket = (
  cli: ?S3MetaDBClient,
  buckets: Array<Bucket>,
  setBuckets: (Array<Bucket>) => void
): RemoveBucket =>
  useCallback(
    async (...names: Array<string>): Promise<void> => {
      if (!cli) return;

      await Promise.all(names.map(n => cli.deleteBucket(n)));

      const deleteNames = new Set(names);
      setBuckets(buckets.filter(b => !deleteNames.has(b.name)));
    },
    [cli, buckets, setBuckets]
  );
